@import "~node_modules/proceduralsystem-clientcomponents/assets/styles/variables";

.sidebar-dialog {
  height: 100%;
  width: 368px;

  mat-dialog-container.mat-dialog-container {
    margin: 0;
    padding: 0;
    border-radius: 0;
    overflow: hidden;

    .sidebar-header {
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $background-light;
      color: $white;
      padding: 0 16px;

      .header-title {
        color: $white;
        font-family: Montserrat-Medium, sans-serif;
        font-weight: 500;
        margin: 0;
        font-size: 13px;
        letter-spacing: 1.8px;
        line-height: 16px;
        text-transform: uppercase; 
      }

      mat-icon {
        color: $white;
        cursor: pointer;
      }
    }
  }
}

.sidebar-dialog-overlay-dark {
  background-color: rgba(0,0,0,0.8);
}