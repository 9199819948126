.slot-position {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin-right: 24px;
  border-radius: 50%;
  background-color: $primary-40;
  font-family: Montserrat-Bold, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;

  &.dragable-slot {
    cursor: pointer;
  }
}

.slot-label {
  color: rgba(0,0,0,0.87);
  font-family: Montserrat-Regular, sans-serif;
  font-size: 15px;
  letter-spacing: 0.47px;
  line-height: 24px;
  margin: 0;
  flex-basis: 50%;
  flex-shrink: 1;
  padding-right: 16px;
}
