.spinner-bg {
  position: absolute;
  top: 0;
  opacity: .75;
  background-color: #000;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.spinner-wrapper {
  position: absolute;
  top: 50%;
  margin-top: -106.5px;
  left: 50%;
  margin-left: -250px;

  img {
    position: absolute;
    text-align: center;
    vertical-align: middle;
  }
}

#stage{
  -webkit-perspective: 1500px;
  -moz-perspective: 1500px;
  perspective: 1500px;
}
#top-img{
  z-index: 2;

  -webkit-animation-name: top-img;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: .5s;

  animation-name: top-img;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 3s;

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
#bottom-img{
  z-index: 1;
}

@-webkit-keyframes top-img {
  from{ -webkit-transform:rotateY(0deg);   }
  to  { -webkit-transform:rotateY(360deg); }
}

@keyframes top-img {
  from {  -moz-transform:rotateY(0deg);
          -ms-transform:rotateY(0deg);
          transform:rotateY(0deg);
       }
  to   {
          -moz-transform:rotateY(360deg);
          -ms-transform:rotateY(360deg);
          transform:rotateY(360deg);
       }
}