@import '~node_modules/proceduralsystem-clientcomponents/assets/styles/styles';
@import '~node_modules/proceduralsystem-clientcomponents/assets/styles/variables';
@import '~node_modules/proceduralsystem-cbs-common-components/assets/styles/styles';

// Component imports
@import "./components/spinner";
@import "./components/slot-position";
@import "./components/sidebar";

html {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

body {
    margin: 0;
}

@-moz-document url-prefix() {
  // Firefox issue with Montserrat font boldness fix
  body {
    // Applying same style for <strong> as Chromium user agents
    *:not(strong) {
      font-weight: lighter !important;
    }

    * strong {
      font-weight: bold !important;
      * {
        font-weight: bold !important;
      }
    }
  }
}

.mat-tooltip.white-tooltip {
  background-color: $white;
  padding: 16px;
  margin-bottom: 5px;
  max-width: 280px;
  width: 280px;
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2);
  color: $background-dark;
  font-size: 15px;
  font-family: 'Montserrat-Medium', sans-serif;
  letter-spacing: 0.47px;
  line-height: 24px;
}

.main-content {
  margin: 0;
  padding: 40px 30px 58px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  h1 {
    font-family: 'WarnockPro-Semibold', sans-serif;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 48px;
  }

  .title-section {
    margin-bottom: 32px;

    > h1 {
      padding: 0;
      margin: 0;
    }
  }

  .page-heading {
    padding: 0;
    margin: 0 0 40px 0;
  }
}

.no-items {
  text-align: center;
  margin: 6rem 0;
}

.clickable {
  cursor: pointer;
}

.list-panel {
  background-color: $white;
  padding: 1.5rem;
}
