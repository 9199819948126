/*----------------------------------------------------------------------------------------------Fonts*/
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");
@font-face {
  font-family: "WarnockPro-Regular";
  src: url("../fonts/WarnockPro/WarnockPro-Regular.eot");
  src: url("../fonts/WarnockPro/WarnockPro-Regular.woff2") format("woff2"), url("../fonts/WarnockPro/WarnockPro-Regular.woff") format("woff"), url("../fonts/WarnockPro/WarnockPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "WarnockPro-Semibold";
  src: url("../fonts/WarnockPro/WarnockPro-Semibold.eot");
  src: url("../fonts/WarnockPro/WarnockPro-Semibold.woff2") format("woff2"), url("../fonts/WarnockPro/WarnockPro-Semibold.woff") format("woff"), url("../fonts/WarnockPro/WarnockPro-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "WarnockPro-Bold";
  src: url("../fonts/WarnockPro/WarnockPro-Bold.eot");
  src: url("../fonts/WarnockPro/WarnockPro-Bold.woff2") format("woff2"), url("../fonts/WarnockPro/WarnockPro-Bold.woff") format("woff"), url("../fonts/WarnockPro/WarnockPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../fonts/Montserrat/Montserrat-Regular.eot");
  src: url("../fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("../fonts/Montserrat/Montserrat-Light.eot");
  src: url("../fonts/Montserrat/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-Light.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-LightItalic";
  src: url("../fonts/Montserrat/Montserrat-LightItalic.eot");
  src: url("../fonts/Montserrat/Montserrat-LightItalic.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-LightItalic.woff") format("woff"), url("../fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("../fonts/Montserrat/Montserrat-Bold.eot");
  src: url("../fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Semibold";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.eot");
  src: url("../fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-SemiBold.woff") format("woff"), url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("../fonts/Montserrat/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat/Montserrat-Medium.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}
/*----------------------------------------------------------------------------------------------Colors*/
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #b39841;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #169b61;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #b39841;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #169b61;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #b39841;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #169b61;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #b39841;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #169b61;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #b39841;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #169b61;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #b39841;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #169b61;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #b39841;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #169b61;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #b39841;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #169b61;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #b39841;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #169b61;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(179, 152, 65, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(179, 152, 65, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(179, 152, 65, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #b39841;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(179, 152, 65, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(179, 152, 65, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(179, 152, 65, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(22, 155, 97, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(22, 155, 97, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(22, 155, 97, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #169b61;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(22, 155, 97, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(22, 155, 97, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(22, 155, 97, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #b39841;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #169b61;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #b39841;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #169b61;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #169b61;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #b39841;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #169b61;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #b39841;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #169b61;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #b39841;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #169b61;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #b39841;
}
.mat-icon.mat-accent {
  color: #169b61;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #b39841;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #169b61;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #e8e2cc;
}

.mat-progress-bar-buffer {
  background-color: #e8e2cc;
}

.mat-progress-bar-fill::after {
  background-color: #b39841;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #c1e2d4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #c1e2d4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #169b61;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #b39841;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #169b61;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #b39841;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #b39841;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #169b61;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #169b61;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #b39841;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #169b61;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #169b61;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(22, 155, 97, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #169b61;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #b39841;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(179, 152, 65, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #b39841;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #b39841;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(179, 152, 65, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #169b61;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(22, 155, 97, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #b39841;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #169b61;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(251, 242, 199, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #b39841;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(192, 228, 209, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #169b61;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(251, 242, 199, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #b39841;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(192, 228, 209, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #169b61;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #b39841;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #169b61;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #169b61;
}

/*----------------------------------------------------------------------------------------------Colors*/
button.btn-primary-flat, button.btn-primary-flat-icon {
  height: 40px;
  border-radius: 0;
}
button.btn-primary-flat .mat-button-wrapper, button.btn-primary-flat-icon .mat-button-wrapper {
  height: 16px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.07px;
  line-height: 16px;
  text-align: center;
}
button.btn-primary-flat.mat-button-disabled, button.btn-primary-flat-icon.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
button.btn-primary-flat.mat-button-disabled .mat-button-wrapper, button.btn-primary-flat-icon.mat-button-disabled .mat-button-wrapper {
  color: rgba(0, 0, 0, 0.38) !important;
  font-weight: 600 !important;
}
button.btn-primary-flat:not(.mat-button-disabled):hover .mat-ripple, button.btn-primary-flat-icon:not(.mat-button-disabled):hover .mat-ripple {
  background-color: rgba(4, 88, 52, 0.04);
}
button.btn-primary-flat:not(.mat-button-disabled):focus .mat-ripple, button.btn-primary-flat-icon:not(.mat-button-disabled):focus .mat-ripple {
  background-color: rgba(4, 88, 52, 0.12);
}
button.btn-primary-flat:not(.mat-button-disabled):active .mat-ripple, button.btn-primary-flat-icon:not(.mat-button-disabled):active .mat-ripple {
  background-color: rgba(4, 88, 52, 0.08);
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

button.btn-secondary-stroked, button.btn-secondary-stroked-icon {
  height: 40px;
  border-radius: 0;
}
button.btn-secondary-stroked .mat-button-wrapper, button.btn-secondary-stroked-icon .mat-button-wrapper {
  height: 16px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.07px;
  line-height: 16px;
  text-align: center;
}
button.btn-secondary-stroked.mat-button-disabled, button.btn-secondary-stroked-icon.mat-button-disabled {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
button.btn-secondary-stroked.mat-button-disabled .mat-button-wrapper, button.btn-secondary-stroked-icon.mat-button-disabled .mat-button-wrapper {
  color: rgba(0, 0, 0, 0.38) !important;
  font-weight: 600 !important;
}
button.btn-secondary-stroked:not(.mat-button-disabled):hover .mat-ripple, button.btn-secondary-stroked-icon:not(.mat-button-disabled):hover .mat-ripple {
  background-color: rgba(179, 152, 65, 0.04);
}
button.btn-secondary-stroked:not(.mat-button-disabled):focus .mat-ripple, button.btn-secondary-stroked-icon:not(.mat-button-disabled):focus .mat-ripple {
  background-color: rgba(179, 152, 65, 0.12);
}
button.btn-secondary-stroked:not(.mat-button-disabled):active .mat-ripple, button.btn-secondary-stroked-icon:not(.mat-button-disabled):active .mat-ripple {
  background-color: rgba(179, 152, 65, 0.08);
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

button.btn-basic, button.btn-basic-icon {
  height: 40px;
  border-radius: 0;
}
button.btn-basic .mat-button-wrapper, button.btn-basic-icon .mat-button-wrapper {
  height: 16px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.07px;
  line-height: 16px;
  text-align: center;
}
button.btn-basic.mat-button-disabled .mat-button-wrapper, button.btn-basic-icon.mat-button-disabled .mat-button-wrapper {
  color: rgba(0, 0, 0, 0.38) !important;
  font-weight: 600 !important;
}
button.btn-basic:not(.mat-button-disabled):hover .mat-ripple, button.btn-basic-icon:not(.mat-button-disabled):hover .mat-ripple {
  background-color: rgba(179, 152, 65, 0.04);
}
button.btn-basic:not(.mat-button-disabled):focus .mat-ripple, button.btn-basic-icon:not(.mat-button-disabled):focus .mat-ripple {
  background-color: rgba(179, 152, 65, 0.12);
}
button.btn-basic:not(.mat-button-disabled):active .mat-ripple, button.btn-basic-icon:not(.mat-button-disabled):active .mat-ripple {
  background-color: rgba(179, 152, 65, 0.08);
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

button.btn-text-icon {
  padding-left: 5px;
}
button.btn-text-icon mat-icon {
  margin-right: 3px;
}

button.mat-button-icon mat-icon {
  color: rgba(0, 0, 0, 0.64);
}

mat-button-toggle-group.mat-button-toggle-group.button-toggle {
  border-radius: unset;
  height: 40px;
}
mat-button-toggle-group.mat-button-toggle-group.button-toggle mat-button-toggle.mat-button-toggle.mat-button-toggle-appearance-standard {
  height: 40px;
}
mat-button-toggle-group.mat-button-toggle-group.button-toggle mat-button-toggle.mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: rgba(179, 152, 65, 0.08);
}
mat-button-toggle-group.mat-button-toggle-group.button-toggle mat-button-toggle.mat-button-toggle.mat-button-toggle-appearance-standard:hover {
  background-color: rgba(179, 152, 65, 0.04);
}
mat-button-toggle-group.mat-button-toggle-group.button-toggle mat-button-toggle.mat-button-toggle.mat-button-toggle-appearance-standard.cdk-focused {
  background-color: rgba(179, 152, 65, 0.12);
}
mat-button-toggle-group.mat-button-toggle-group.button-toggle mat-button-toggle.mat-button-toggle.mat-button-toggle-appearance-standard.cdk-focused .mat-button-toggle-ripple {
  background-color: rgba(179, 152, 65, 0.12);
}
mat-button-toggle-group.mat-button-toggle-group.button-toggle mat-button-toggle.mat-button-toggle.mat-button-toggle-appearance-standard.cdk-focused .mat-ripple-element {
  background-color: rgba(179, 152, 65, 0.12);
}
mat-button-toggle-group.mat-button-toggle-group.button-toggle mat-button-toggle.mat-button-toggle.mat-button-toggle-appearance-standard button.mat-button-toggle-button {
  height: 40px;
}
mat-button-toggle-group.mat-button-toggle-group.button-toggle mat-button-toggle.mat-button-toggle.mat-button-toggle-appearance-standard button.mat-button-toggle-button .mat-button-toggle-label-content {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Semibold";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.07px;
  line-height: 16px;
  margin: auto;
  text-transform: uppercase;
  padding: 0 8px;
}
mat-button-toggle-group.mat-button-toggle-group.button-toggle mat-button-toggle.mat-button-toggle.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: rgba(179, 152, 65, 0.12);
  border: unset;
}

mat-checkbox.mat-checkbox.checkbox-mat {
  margin: 8px 0;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-checkbox-disabled {
  opacity: 0.38;
}
mat-checkbox.mat-checkbox.checkbox-mat .mat-checkbox-inner-container {
  margin-right: 15px;
}
mat-checkbox.mat-checkbox.checkbox-mat .mat-checkbox-inner-container, mat-checkbox.mat-checkbox.checkbox-mat .mat-checkbox-frame, mat-checkbox.mat-checkbox.checkbox-mat .mat-checkbox-background {
  width: 18px;
  height: 18px;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-checkbox-checked .mat-checkbox-background {
  background-color: #b39841;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-checkbox-checked.mat-accent.cdk-focused .mat-checkbox-ripple {
  border: 1px solid #b39841;
  border-radius: 50%;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-checkbox-checked.mat-accent.cdk-focused .mat-ripple-element {
  background-color: #b39841;
  opacity: 0.12;
  transition: background-color 0s;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-checkbox-checked.mat-accent .mat-ripple-element {
  background-color: #b39841;
  transition: background-color 0s;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-checkbox-checked.mat-accent :active .mat-ripple-element {
  background-color: #b39841;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-checkbox-checked.mat-accent :hover .mat-ripple-element {
  background-color: #b39841;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-accent.cdk-focused .mat-checkbox-ripple {
  border: 1px solid #ffffff;
  border-radius: 50%;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-accent.cdk-focused .mat-ripple-element {
  background-color: #000000;
  opacity: 0.12;
  transition: background-color 0s;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-accent .mat-ripple-element {
  background-color: #000000;
  transition: background-color 0s;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-accent :active .mat-ripple-element {
  background-color: #000000;
}
mat-checkbox.mat-checkbox.checkbox-mat.mat-accent :hover .mat-ripple-element {
  background-color: #000000;
}

mat-chip.mat-chip.mat-standard-chip.chip, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true], mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true] {
  height: auto;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  color: #b39841;
  font-family: "Montserrat-Medium";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
}
mat-chip.mat-chip.mat-standard-chip.chip button.mat-chip-remove, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true] button.mat-chip-remove, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true] button.mat-chip-remove {
  opacity: unset;
}
mat-chip.mat-chip.mat-standard-chip.chip button.mat-chip-remove:hover, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true] button.mat-chip-remove:hover, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true] button.mat-chip-remove:hover {
  opacity: unset !important;
}
mat-chip.mat-chip.mat-standard-chip.chip button.mat-chip-remove:focus, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true] button.mat-chip-remove:focus, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true] button.mat-chip-remove:focus {
  outline: 2px solid rgba(0, 0, 0, 0.12);
}
mat-chip.mat-chip.mat-standard-chip.chip mat-icon, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true] mat-icon, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true] mat-icon {
  color: #b39841;
}
mat-chip.mat-chip.mat-standard-chip.chip.mat-standard-chip:after, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true].mat-standard-chip:after, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true].mat-standard-chip:after {
  background: unset;
}
mat-chip.mat-chip.mat-standard-chip.chip:hover, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true]:hover {
  background-color: rgba(179, 152, 65, 0.04);
  opacity: unset;
}
mat-chip.mat-chip.mat-standard-chip.chip:focus, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true]:focus {
  background-color: rgba(179, 152, 65, 0.12);
  opacity: unset;
}
mat-chip.mat-chip.mat-standard-chip.chip:active, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true]:active {
  background-color: rgba(179, 152, 65, 0.08);
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}
mat-chip.mat-chip.mat-standard-chip.chip .mat-chip-ripple .mat-ripple-element, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true] .mat-chip-ripple .mat-ripple-element {
  background-color: rgba(179, 152, 65, 0.1);
}
mat-chip.mat-chip.mat-standard-chip.chip mat-icon.selected-icon, mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true] mat-icon.selected-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  margin-left: -5px;
  margin-right: 6px;
  padding: 0;
}
mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-selected[aria-selected=true] {
  background-color: rgba(179, 152, 65, 0.08);
}
mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true] {
  background-color: #ffffff;
  color: rgba(179, 152, 65, 0.38);
  opacity: unset !important;
}
mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true] mat-icon {
  color: rgba(179, 152, 65, 0.38);
}
mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true]:hover {
  background-color: #ffffff;
}
mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true]:focus {
  box-shadow: unset;
  background-color: #ffffff;
}
mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true]:active {
  box-shadow: unset;
}
mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true].mat-standard-chip:after {
  background: unset;
}
mat-chip.mat-chip.mat-standard-chip.chip.mat-chip-disabled[disabled=true] .mat-chip-ripple .mat-ripple-element {
  background-color: unset;
}

mat-chip.mat-chip.mat-standard-chip.chip-live-fill, mat-chip.mat-chip.mat-standard-chip.chip-live-empty {
  margin: 4px;
  height: 32px;
  font-family: "Montserrat-SemiBold";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.07px;
  line-height: 16px;
  text-transform: uppercase;
}
mat-chip.mat-chip.mat-standard-chip.chip-live-fill mat-icon, mat-chip.mat-chip.mat-standard-chip.chip-live-empty mat-icon {
  font-size: 10px;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
mat-chip.mat-chip.mat-standard-chip.chip-live-fill:focus, mat-chip.mat-chip.mat-standard-chip.chip-live-empty:focus {
  box-shadow: unset;
}
mat-chip.mat-chip.mat-standard-chip.chip-live-fill.mat-standard-chip:after, mat-chip.mat-chip.mat-standard-chip.chip-live-empty.mat-standard-chip:after {
  background: unset;
}
mat-chip.mat-chip.mat-standard-chip.chip-live-fill .mat-chip-ripple .mat-ripple-element, mat-chip.mat-chip.mat-standard-chip.chip-live-empty .mat-chip-ripple .mat-ripple-element {
  background-color: unset;
}
mat-chip.mat-chip.mat-standard-chip.chip-live-fill {
  color: #ffffff;
  background-color: #E71D32;
}
mat-chip.mat-chip.mat-standard-chip.chip-live-fill mat-icon {
  color: #ffffff;
}
mat-chip.mat-chip.mat-standard-chip.chip-live-fill:focus {
  background-color: #E71D32;
}
mat-chip.mat-chip.mat-standard-chip.chip-live-empty {
  color: #E71D32;
  background-color: #ffffff;
}
mat-chip.mat-chip.mat-standard-chip.chip-live-empty mat-icon {
  color: #E71D32;
}
mat-chip.mat-chip.mat-standard-chip.chip-live-empty:focus {
  background-color: #ffffff;
}

mat-chip.mat-chip.mat-standard-chip.chip-draft {
  color: #b39841;
  background-color: rgba(179, 152, 65, 0.12);
}
mat-chip.mat-chip.mat-standard-chip.chip-draft:focus {
  background-color: rgba(179, 152, 65, 0.12);
}
mat-chip.mat-chip.mat-standard-chip.chip-error {
  color: #B00020;
  background-color: rgba(255, 69, 58, 0.12);
}
mat-chip.mat-chip.mat-standard-chip.chip-error:focus {
  background-color: rgba(255, 69, 58, 0.12);
}
mat-chip.mat-chip.mat-standard-chip.chip-published, mat-chip.mat-chip.mat-standard-chip.chip-inprogress, mat-chip.mat-chip.mat-standard-chip.chip-paused {
  color: #169B61;
  background-color: rgba(22, 155, 98, 0.12);
}
mat-chip.mat-chip.mat-standard-chip.chip-published:focus, mat-chip.mat-chip.mat-standard-chip.chip-inprogress:focus, mat-chip.mat-chip.mat-standard-chip.chip-paused:focus {
  background-color: rgba(22, 155, 98, 0.12);
}
mat-chip.mat-chip.mat-standard-chip.chip-draft, mat-chip.mat-chip.mat-standard-chip.chip-published, mat-chip.mat-chip.mat-standard-chip.chip-inprogress, mat-chip.mat-chip.mat-standard-chip.chip-paused, mat-chip.mat-chip.mat-standard-chip.chip-error {
  margin: 4px;
  border-radius: 20px;
  height: 32px;
  font-family: "Montserrat-Medium";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: center;
}
mat-chip.mat-chip.mat-standard-chip.chip-draft:focus, mat-chip.mat-chip.mat-standard-chip.chip-published:focus, mat-chip.mat-chip.mat-standard-chip.chip-inprogress:focus, mat-chip.mat-chip.mat-standard-chip.chip-paused:focus, mat-chip.mat-chip.mat-standard-chip.chip-error:focus {
  box-shadow: unset;
}
mat-chip.mat-chip.mat-standard-chip.chip-draft.mat-standard-chip:after, mat-chip.mat-chip.mat-standard-chip.chip-published.mat-standard-chip:after, mat-chip.mat-chip.mat-standard-chip.chip-inprogress.mat-standard-chip:after, mat-chip.mat-chip.mat-standard-chip.chip-paused.mat-standard-chip:after, mat-chip.mat-chip.mat-standard-chip.chip-error.mat-standard-chip:after {
  background: unset;
}
mat-chip.mat-chip.mat-standard-chip.chip-draft .mat-chip-ripple .mat-ripple-element, mat-chip.mat-chip.mat-standard-chip.chip-published .mat-chip-ripple .mat-ripple-element, mat-chip.mat-chip.mat-standard-chip.chip-inprogress .mat-chip-ripple .mat-ripple-element, mat-chip.mat-chip.mat-standard-chip.chip-paused .mat-chip-ripple .mat-ripple-element, mat-chip.mat-chip.mat-standard-chip.chip-error .mat-chip-ripple .mat-ripple-element {
  background-color: unset;
}

.dialog-mat {
  min-width: 280px;
  max-width: 560px;
}
.dialog-mat mat-dialog-container.mat-dialog-container {
  margin: 0;
  padding: 0;
  border-radius: 0;
}
.dialog-mat mat-dialog-container.mat-dialog-container .modal-dialog {
  background-color: #ffffff;
  display: block;
  width: 100%;
  margin: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.dialog-mat mat-dialog-container.mat-dialog-container .modal-dialog .modal-header {
  position: relative;
  background-color: #e6e0cd;
  padding: 12px 16px;
  display: flex;
  align-content: center;
  margin-top: 0;
  margin-bottom: 10px;
}
.dialog-mat mat-dialog-container.mat-dialog-container .modal-dialog .modal-header .mat-close-icon {
  position: absolute;
  right: 0;
  margin-right: 16px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.dialog-mat mat-dialog-container.mat-dialog-container .modal-dialog .modal-header .mat-dialog-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Regular";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  padding: 0;
}
.dialog-mat mat-dialog-container.mat-dialog-container .modal-dialog mat-dialog-content.mat-dialog-content.modal-body {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Medium";
  font-size: 15px;
  letter-spacing: 0.47px;
  line-height: 24px;
  margin: 28px 0 28px 0;
  padding: 0 16px;
}
.dialog-mat mat-dialog-container.mat-dialog-container .modal-dialog mat-dialog-actions.mat-dialog-actions.dialog-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
  padding-right: 8px;
  padding-bottom: 8px;
  min-height: unset;
  gap: 8px;
  margin-bottom: 0;
}
.dialog-mat mat-dialog-container.mat-dialog-container .modal-dialog mat-dialog-actions.mat-dialog-actions.dialog-buttons button {
  margin: 0;
}

.oir-snackbar-error {
  border-radius: 2px !important;
  background-color: #ffe5e9;
  border-left: 10px solid #c30e2e;
  color: #c30e2e;
}

.oir-snackbar {
  border-radius: 2px !important;
}

.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar {
  box-shadow: unset;
  border-radius: unset;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel.mat-expansion-panel-spacing, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.mat-expansion-panel-spacing {
  margin: 0;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel.cancel-icon .mat-expansion-panel-header[aria-expanded=true], .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.cancel-icon .mat-expansion-panel-header[aria-expanded=true] {
  padding-right: 13px;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel.cancel-icon .mat-expansion-panel-header[aria-expanded=true] mat-icon, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.cancel-icon .mat-expansion-panel-header[aria-expanded=true] mat-icon {
  color: rgba(0, 0, 0, 0.6);
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel:not(:last-child) .mat-expansion-panel-header[aria-expanded=false]::after,
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel:not(:last-child) .mat-expansion-panel-content::after, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-header[aria-expanded=false]::after,
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-content::after {
  content: " ";
  width: calc(100% - 32px);
  position: absolute;
  height: 100%;
  left: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel:last-child .mat-expansion-panel-header[aria-expanded=false]::after, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:last-child .mat-expansion-panel-header[aria-expanded=false]::after {
  content: " ";
  width: calc(100% - 32px);
  position: absolute;
  height: 100%;
  left: 16px;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-content::after, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header::after, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-content::after, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header::after {
  top: 0;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel.mat-expanded .mat-expansion-panel-content, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.mat-expanded .mat-expansion-panel-content {
  padding-bottom: 24px;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-body, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-body, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header {
  z-index: 10;
  padding: 0 16px;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-action-row, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-action-row {
  z-index: 10;
  border: unset;
  padding-bottom: unset;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header {
  height: 72px;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=false]:hover, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=false]:active, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=false]:focus, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=false]:hover, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=false]:active, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=false]:focus {
  background-color: rgba(0, 0, 0, 0.04);
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=true], .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=true] {
  padding-right: 21px;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header mat-icon, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-icon {
  margin: auto;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-indicator, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-indicator {
  width: 12px;
  height: 25px;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel mat-panel-title.mat-expansion-panel-header-title, .accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar mat-panel-title.mat-expansion-panel-header-title {
  margin: auto;
  margin-right: 16px;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-panel-title.mat-expansion-panel-header-title {
  margin: auto;
  margin-right: 16px;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-panel-title.mat-expansion-panel-header-title.expension-panel-avatar-wrapper {
  margin-right: 24px;
  flex-grow: 0;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-panel-title.mat-expansion-panel-header-title.expension-panel-avatar-wrapper .img-avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f4f2ea;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-content {
  margin-left: 64px;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-header[aria-expanded=false]::after,
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-content::after {
  width: calc(100% - 96px);
  right: 16px;
  left: unset;
}
.accordion-panel-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:last-child .mat-expansion-panel-header[aria-expanded=false]::after {
  width: calc(100% - 96px);
  right: 16px;
  left: unset;
}

.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar {
  box-shadow: unset;
  border-radius: unset;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel.mat-expansion-panel-spacing, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.mat-expansion-panel-spacing {
  margin: 0;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel.cancel-icon .mat-expansion-panel-header[aria-expanded=true], .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.cancel-icon .mat-expansion-panel-header[aria-expanded=true] {
  padding-right: 29px;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel.cancel-icon .mat-expansion-panel-header[aria-expanded=true] mat-icon, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.cancel-icon .mat-expansion-panel-header[aria-expanded=true] mat-icon {
  color: rgba(0, 0, 0, 0.6);
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel:not(:last-child) .mat-expansion-panel-header[aria-expanded=false]::after,
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel:not(:last-child) .mat-expansion-panel-content::after, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-header[aria-expanded=false]::after,
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-content::after {
  content: " ";
  width: calc(100% - 64px);
  position: absolute;
  height: 100%;
  left: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel:last-child .mat-expansion-panel-header[aria-expanded=false]::after, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:last-child .mat-expansion-panel-header[aria-expanded=false]::after {
  content: " ";
  width: calc(100% - 64px);
  position: absolute;
  height: 100%;
  left: 32px;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-content::after, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header::after, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-content::after, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header::after {
  top: 0;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel.mat-expanded .mat-expansion-panel-content, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.mat-expanded .mat-expansion-panel-content {
  padding-bottom: 24px;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-body, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-body, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header {
  z-index: 10;
  padding: 0 32px;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-action-row, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-action-row {
  z-index: 10;
  border: unset;
  padding-bottom: unset;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header {
  height: 72px;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=false]:hover, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=false]:active, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=false]:focus, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=false]:hover, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=false]:active, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=false]:focus {
  background-color: rgba(0, 0, 0, 0.04);
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=true], .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=true] {
  padding-right: 21px;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header mat-icon, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-icon {
  margin: auto;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-indicator, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-indicator {
  width: 12px;
  height: 25px;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel mat-panel-title.mat-expansion-panel-header-title, .accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar mat-panel-title.mat-expansion-panel-header-title {
  margin: auto;
  margin-right: 16px;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-panel-title.mat-expansion-panel-header-title {
  margin: auto;
  margin-right: 16px;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-panel-title.mat-expansion-panel-header-title.expension-panel-avatar-wrapper {
  margin-right: 24px;
  flex-grow: 0;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-panel-title.mat-expansion-panel-header-title.expension-panel-avatar-wrapper .img-avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f4f2ea;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-content {
  margin-left: 64px;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-header[aria-expanded=false]::after,
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-content::after {
  width: calc(100% - 128px);
  right: 32px;
  left: unset;
}
.accordion-panel-medium-padding-medium mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:last-child .mat-expansion-panel-header[aria-expanded=false]::after {
  width: calc(100% - 128px);
  right: 32px;
  left: unset;
}

.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar {
  box-shadow: unset;
  border-radius: unset;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel.mat-expansion-panel-spacing, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.mat-expansion-panel-spacing {
  margin: 0;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel.cancel-icon .mat-expansion-panel-header[aria-expanded=true], .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.cancel-icon .mat-expansion-panel-header[aria-expanded=true] {
  padding-right: 13px;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel.cancel-icon .mat-expansion-panel-header[aria-expanded=true] mat-icon, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.cancel-icon .mat-expansion-panel-header[aria-expanded=true] mat-icon {
  color: rgba(0, 0, 0, 0.6);
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel:not(:last-child) .mat-expansion-panel-header[aria-expanded=false]::after,
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel:not(:last-child) .mat-expansion-panel-content::after, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-header[aria-expanded=false]::after,
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-content::after {
  content: " ";
  width: calc(100% - 32px);
  position: absolute;
  height: 100%;
  left: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel:last-child .mat-expansion-panel-header[aria-expanded=false]::after, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:last-child .mat-expansion-panel-header[aria-expanded=false]::after {
  content: " ";
  width: calc(100% - 32px);
  position: absolute;
  height: 100%;
  left: 16px;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-content::after, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header::after, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-content::after, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header::after {
  top: 0;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel.mat-expanded .mat-expansion-panel-content, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.mat-expanded .mat-expansion-panel-content {
  padding-bottom: 24px;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-body, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-body, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header {
  z-index: 10;
  padding: 0 16px;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-action-row, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-action-row {
  z-index: 10;
  border: unset;
  padding-bottom: unset;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header {
  height: 48px;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=false]:hover, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=false]:active, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=false]:focus, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=false]:hover, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=false]:active, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=false]:focus {
  background-color: rgba(0, 0, 0, 0.04);
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header[aria-expanded=true], .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header[aria-expanded=true] {
  padding-right: 21px;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-panel-header mat-icon, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-icon {
  margin: auto;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel .mat-expansion-indicator, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-indicator {
  width: 12px;
  height: 25px;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel mat-panel-title.mat-expansion-panel-header-title, .accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar mat-panel-title.mat-expansion-panel-header-title {
  margin: auto;
  margin-right: 16px;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-panel-title.mat-expansion-panel-header-title {
  margin: auto;
  margin-right: 16px;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-panel-title.mat-expansion-panel-header-title.expension-panel-avatar-wrapper {
  margin-right: 24px;
  flex-grow: 0;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-header mat-panel-title.mat-expansion-panel-header-title.expension-panel-avatar-wrapper .img-avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f4f2ea;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar .mat-expansion-panel-content {
  margin-left: 64px;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-header[aria-expanded=false]::after,
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:not(:last-child) .mat-expansion-panel-content::after {
  width: calc(100% - 96px);
  right: 16px;
  left: unset;
}
.accordion-panel-default mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar:last-child .mat-expansion-panel-header[aria-expanded=false]::after {
  width: calc(100% - 96px);
  right: 16px;
  left: unset;
}

.text-content-in-panel-header mat-expansion-panel.mat-expansion-panel.expansion-panel.header-with-text-content .mat-expansion-panel-content {
  margin-right: 64px;
}
.text-content-in-panel-header mat-expansion-panel.mat-expansion-panel.expansion-panel.header-with-text-content .header-infix-content, .text-content-in-panel-header mat-expansion-panel.mat-expansion-panel.expansion-panel.header-with-text-content .mat-expansion-panel-content {
  color: #444444;
  font-family: "Montserrat-Regular";
  font-size: 15px;
  letter-spacing: 0.48px;
  line-height: 20px;
}
.text-content-in-panel-header mat-expansion-panel.mat-expansion-panel.expansion-panel.header-with-text-content .mat-expansion-panel-header .header-infix .header-infix-title {
  font-family: "Montserrat-Medium";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.8px;
  line-height: 16px;
  margin-bottom: 0;
}
.text-content-in-panel-header mat-expansion-panel.mat-expansion-panel.expansion-panel.header-with-text-content .mat-expansion-panel-header[aria-expanded=true] {
  height: 40px;
  padding-top: 20px;
  margin-bottom: 10px;
}
.text-content-in-panel-header mat-expansion-panel.mat-expansion-panel.expansion-panel.header-with-text-content .mat-expansion-panel-header[aria-expanded=true] .header-infix-content {
  display: none;
}
.text-content-in-panel-header mat-expansion-panel.mat-expansion-panel.expansion-panel.header-with-text-content .mat-expansion-panel-header[aria-expanded=false] {
  height: 88px;
  padding-bottom: 15px;
}
.text-content-in-panel-header mat-expansion-panel.mat-expansion-panel.expansion-panel.header-with-text-content .mat-expansion-panel-header[aria-expanded=false] .mat-expansion-panel-header-description.header-infix-wrapper {
  margin-top: 24px;
}
.text-content-in-panel-header mat-expansion-panel.mat-expansion-panel.expansion-panel.header-with-text-content .mat-expansion-panel-header[aria-expanded=false] .mat-expansion-panel-header-description.header-infix-wrapper .header-infix .header-infix-title {
  margin-bottom: 10px;
}

mat-accordion.mat-accordion.accordion-shadow {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  border-radius: 4px;
  display: block;
}

mat-accordion.mat-accordion mat-expansion-panel.mat-expansion-panel.expansion-panel.off-header-divider .mat-expansion-panel-header[aria-expanded=false]::after, mat-accordion.mat-accordion mat-expansion-panel.mat-expansion-panel.expansion-panel-with-avatar.off-header-divider .mat-expansion-panel-header[aria-expanded=false]::after {
  border: unset;
}

.header-postfix {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.header-postfix mat-icon {
  display: flex;
  color: rgba(0, 0, 0, 0.6);
}

.more-menu-button {
  z-index: 50;
}

mat-form-field.mat-form-field.input-field, mat-form-field.mat-form-field.input-outline, mat-form-field.mat-form-field.input-field--disabled, mat-form-field.mat-form-field.input-outline--disabled {
  width: 100%;
}
mat-form-field.mat-form-field.input-field .mat-form-field-label-wrapper, mat-form-field.mat-form-field.input-outline .mat-form-field-label-wrapper, mat-form-field.mat-form-field.input-field--disabled .mat-form-field-label-wrapper, mat-form-field.mat-form-field.input-outline--disabled .mat-form-field-label-wrapper {
  top: -0.74375em;
}
mat-form-field.mat-form-field.input-field--disabled mat-icon, mat-form-field.mat-form-field.input-outline--disabled mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
mat-form-field.mat-form-field.input-field mat-hint, mat-form-field.mat-form-field.input-outline mat-hint, mat-form-field.mat-form-field.input-field--disabled mat-hint, mat-form-field.mat-form-field.input-outline--disabled mat-hint {
  height: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
}
mat-form-field.mat-form-field.input-field mat-error, mat-form-field.mat-form-field.input-outline mat-error {
  height: 16px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
}
mat-form-field.mat-form-field.input-field label, mat-form-field.mat-form-field.input-outline label, mat-form-field.mat-form-field.input-field--disabled label, mat-form-field.mat-form-field.input-outline--disabled label {
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.47px;
  line-height: 24px;
}
mat-form-field.mat-form-field.input-field input, mat-form-field.mat-form-field.input-field mat-select, mat-form-field.mat-form-field.input-field textarea, mat-form-field.mat-form-field.input-outline input, mat-form-field.mat-form-field.input-outline mat-select, mat-form-field.mat-form-field.input-outline textarea, mat-form-field.mat-form-field.input-field--disabled input, mat-form-field.mat-form-field.input-field--disabled mat-select, mat-form-field.mat-form-field.input-field--disabled textarea, mat-form-field.mat-form-field.input-outline--disabled input, mat-form-field.mat-form-field.input-outline--disabled mat-select, mat-form-field.mat-form-field.input-outline--disabled textarea {
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.47px;
  line-height: 24px;
}
mat-form-field.mat-form-field.input-field--disabled label, mat-form-field.mat-form-field.input-outline--disabled label {
  color: rgba(0, 0, 0, 0.38);
}
mat-form-field.mat-form-field.input-field--disabled input, mat-form-field.mat-form-field.input-field--disabled mat-select, mat-form-field.mat-form-field.input-field--disabled textarea, mat-form-field.mat-form-field.input-outline--disabled input, mat-form-field.mat-form-field.input-outline--disabled mat-select, mat-form-field.mat-form-field.input-outline--disabled textarea {
  color: rgba(0, 0, 0, 0.38);
}
mat-form-field.mat-form-field.input-field .mat-placeholder-required, mat-form-field.mat-form-field.input-outline .mat-placeholder-required {
  display: none;
}
mat-form-field.mat-form-field.input-field .mat-select-arrow, mat-form-field.mat-form-field.input-outline .mat-select-arrow, mat-form-field.mat-form-field.input-field--disabled .mat-select-arrow, mat-form-field.mat-form-field.input-outline--disabled .mat-select-arrow {
  border-color: rgba(0, 0, 0, 0.6);
  width: 8px;
  height: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-top: unset;
  border-right: unset;
  transform: rotate(315deg);
}
mat-form-field.mat-form-field.input-field mat-select[aria-expanded=true] .mat-select-arrow, mat-form-field.mat-form-field.input-outline mat-select[aria-expanded=true] .mat-select-arrow {
  transform: rotate(135deg);
}
mat-form-field.mat-form-field.input-field .mat-form-field-subscript-wrapper, mat-form-field.mat-form-field.input-outline .mat-form-field-subscript-wrapper {
  margin-top: 0;
  padding-top: 3px;
}
mat-form-field.mat-form-field.input-field .mat-form-field-infix, mat-form-field.mat-form-field.input-outline .mat-form-field-infix, mat-form-field.mat-form-field.input-field--disabled .mat-form-field-infix, mat-form-field.mat-form-field.input-outline--disabled .mat-form-field-infix {
  border-top: 0;
}
mat-form-field.mat-form-field.input-field .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker, mat-form-field.mat-form-field.input-outline .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker, mat-form-field.mat-form-field.input-field--disabled .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker, mat-form-field.mat-form-field.input-outline--disabled .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker {
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.47px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.input-outline.mat-form-field-hide-placeholder .mat-form-field-label-wrapper, mat-form-field.mat-form-field.input-outline--disabled.mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
  height: 56px;
  padding: 0;
  top: -0.64375em;
}
mat-form-field.mat-form-field.input-outline .mat-form-field-outline, mat-form-field.mat-form-field.input-outline--disabled .mat-form-field-outline {
  background-color: transparent !important;
}
mat-form-field.mat-form-field.input-outline .mat-select-arrow-wrapper, mat-form-field.mat-form-field.input-outline--disabled .mat-select-arrow-wrapper {
  transform: translateY(-10%);
  height: 24px;
  width: 20px;
  vertical-align: middle;
}
mat-form-field.mat-form-field.input-outline .mat-form-field-flex, mat-form-field.mat-form-field.input-outline--disabled .mat-form-field-flex {
  height: 56px;
  padding: 2px 16px;
}
mat-form-field.mat-form-field.input-outline .mat-form-field-flex > .mat-form-field-outline, mat-form-field.mat-form-field.input-outline--disabled .mat-form-field-flex > .mat-form-field-outline {
  top: 0;
}
mat-form-field.mat-form-field.input-outline .mat-form-field-flex > .mat-form-field-outline div, mat-form-field.mat-form-field.input-outline--disabled .mat-form-field-flex > .mat-form-field-outline div {
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-radius: 0;
}
mat-form-field.mat-form-field.input-outline .mat-form-field-outline-gap {
  border-top-style: none;
}
mat-form-field.mat-form-field.input-outline :hover .mat-form-field-flex > .mat-form-field-outline div {
  border-color: rgba(0, 0, 0, 0.6);
  border-width: 1px;
}
mat-form-field.mat-form-field.input-outline.mat-form-field-hide-placeholder .mat-form-field-outline-gap {
  border-top-style: solid;
}
mat-form-field.mat-form-field.input-outline.mat-focused input, mat-form-field.mat-form-field.input-outline.mat-focused textarea {
  caret-color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.input-outline.mat-focused label {
  color: #b39841 !important;
}
mat-form-field.mat-form-field.input-outline.mat-focused .mat-form-field-label .mat-form-field-required-marker {
  color: #b39841;
}
mat-form-field.mat-form-field.input-outline.mat-focused .mat-form-field-flex > .mat-form-field-outline div {
  border-color: #b39841;
  border-width: 1px;
}
mat-form-field.mat-form-field.input-outline.mat-focused.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-style: none;
}
mat-form-field.mat-form-field.input-outline.mat-focused .mat-select-arrow {
  border-color: rgba(0, 0, 0, 0.6);
}
mat-form-field.mat-form-field.input-outline .mat-form-field-outline {
  background-color: #ffffff;
}
mat-form-field.mat-form-field.input-outline.mat-form-field-invalid input, mat-form-field.mat-form-field.input-outline.mat-form-field-invalid textarea {
  caret-color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.input-outline.mat-form-field-invalid mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
mat-form-field.mat-form-field.input-outline.mat-form-field-invalid mat-error {
  color: #b00020;
}
mat-form-field.mat-form-field.input-outline.mat-form-field-invalid label {
  color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.input-outline.mat-form-field-invalid .mat-form-field-flex > .mat-form-field-outline div {
  border-color: #b00020;
}
mat-form-field.mat-form-field.input-outline.mat-form-field-invalid :hover .mat-form-field-flex > .mat-form-field-outline div {
  border-color: rgba(0, 0, 0, 0.6);
}
mat-form-field.mat-form-field.input-field .mat-form-field-flex, mat-form-field.mat-form-field.input-field--disabled .mat-form-field-flex {
  background-color: #f4f2ea;
  border-radius: 0;
  padding: 16px 16px 0px;
}
mat-form-field.mat-form-field.input-field .mat-select-arrow-wrapper, mat-form-field.mat-form-field.input-field--disabled .mat-select-arrow-wrapper {
  transform: translateY(-30%);
  height: 24px;
  width: 20px;
  vertical-align: middle;
}
mat-form-field.mat-form-field.input-field.mat-form-field-invalid input, mat-form-field.mat-form-field.input-field.mat-form-field-invalid textarea {
  caret-color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.input-field.mat-form-field-invalid mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
mat-form-field.mat-form-field.input-field.mat-form-field-invalid mat-error {
  color: #b00020;
}
mat-form-field.mat-form-field.input-field.mat-form-field-invalid label {
  color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.input-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #b00020 !important;
  height: 1px;
}
mat-form-field.mat-form-field.input-field .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
mat-form-field.mat-form-field.input-field :hover .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.6) !important;
  height: 1px;
}
mat-form-field.mat-form-field.input-field :hover .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
mat-form-field.mat-form-field.input-field.mat-focused input, mat-form-field.mat-form-field.input-field.mat-focused textarea {
  caret-color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.input-field.mat-focused label {
  color: #b39841;
}
mat-form-field.mat-form-field.input-field.mat-focused .mat-form-field-label .mat-form-field-required-marker {
  color: #b39841;
}
mat-form-field.mat-form-field.input-field.mat-focused .mat-form-field-ripple {
  background-color: #b39841 !important;
  height: 1px;
}
mat-form-field.mat-form-field.input-field.mat-focused .mat-select-arrow {
  border-color: rgba(0, 0, 0, 0.6);
}

mat-form-field.mat-form-field.input-outline .mat-form-field-flex, mat-form-field.mat-form-field.input-outline--disabled .mat-form-field-flex {
  padding: 2.5px 12px;
}
mat-form-field.mat-form-field.input-field .mat-form-field-label-wrapper, mat-form-field.mat-form-field.input-outline .mat-form-field-label-wrapper, mat-form-field.mat-form-field.input-field--disabled .mat-form-field-label-wrapper, mat-form-field.mat-form-field.input-outline--disabled .mat-form-field-label-wrapper {
  top: -0.64375em !important;
}
mat-form-field.mat-form-field.input-outline.mat-form-field-should-float .mat-form-field-label-wrapper {
  top: -0.94375em;
}
mat-form-field.mat-form-field.input-field .mat-form-field-suffix, mat-form-field.mat-form-field.input-field--disabled .mat-form-field-suffix {
  top: unset;
}
mat-form-field.mat-form-field.input-outline .mat-form-field-suffix, mat-form-field.mat-form-field.input-outline--disabled .mat-form-field-suffix {
  top: 2px;
}
mat-form-field.mat-form-field.input-field mat-icon, mat-form-field.mat-form-field.input-outline mat-icon {
  color: rgba(0, 0, 0, 0.6);
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

mat-selection-list.mat-selection-list.list.mat-list-base .mat-list-item .mat-list-item-content-reverse .mat-list-text, mat-selection-list.mat-selection-list.list.mat-list-base .mat-list-option .mat-list-item-content-reverse .mat-list-text, mat-selection-list.mat-selection-list.list-with-chip.mat-list-base .mat-list-item .mat-list-item-content-reverse .mat-list-text, mat-selection-list.mat-selection-list.list-with-chip.mat-list-base .mat-list-option .mat-list-item-content-reverse .mat-list-text, mat-selection-list.mat-selection-list.list-with-toggle.mat-list-base .mat-list-item .mat-list-item-content-reverse .mat-list-text, mat-selection-list.mat-selection-list.list-with-toggle.mat-list-base .mat-list-option .mat-list-item-content-reverse .mat-list-text, mat-list.mat-list.list.mat-list-base .mat-list-item .mat-list-item-content-reverse .mat-list-text, mat-list.mat-list.list.mat-list-base .mat-list-option .mat-list-item-content-reverse .mat-list-text, mat-list.mat-list.list-with-chip.mat-list-base .mat-list-item .mat-list-item-content-reverse .mat-list-text, mat-list.mat-list.list-with-chip.mat-list-base .mat-list-option .mat-list-item-content-reverse .mat-list-text, mat-list.mat-list.list-with-toggle.mat-list-base .mat-list-item .mat-list-item-content-reverse .mat-list-text, mat-list.mat-list.list-with-toggle.mat-list-base .mat-list-option .mat-list-item-content-reverse .mat-list-text {
  padding: 0;
}
mat-selection-list.mat-selection-list.list.mat-list-base .mat-list-item:not(:last-child) .mat-list-item-content, mat-selection-list.mat-selection-list.list.mat-list-base .mat-list-option:not(:last-child) .mat-list-item-content, mat-selection-list.mat-selection-list.list-with-chip.mat-list-base .mat-list-item:not(:last-child) .mat-list-item-content, mat-selection-list.mat-selection-list.list-with-chip.mat-list-base .mat-list-option:not(:last-child) .mat-list-item-content, mat-list.mat-list.list.mat-list-base .mat-list-item:not(:last-child) .mat-list-item-content, mat-list.mat-list.list.mat-list-base .mat-list-option:not(:last-child) .mat-list-item-content, mat-list.mat-list.list-with-chip.mat-list-base .mat-list-item:not(:last-child) .mat-list-item-content, mat-list.mat-list.list-with-chip.mat-list-base .mat-list-option:not(:last-child) .mat-list-item-content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
mat-selection-list.mat-selection-list.list.mat-list-base .mat-list-item .mat-list-item-content, mat-selection-list.mat-selection-list.list.mat-list-base .mat-list-option .mat-list-item-content, mat-selection-list.mat-selection-list.list-with-chip.mat-list-base .mat-list-item .mat-list-item-content, mat-selection-list.mat-selection-list.list-with-chip.mat-list-base .mat-list-option .mat-list-item-content, mat-list.mat-list.list.mat-list-base .mat-list-item .mat-list-item-content, mat-list.mat-list.list.mat-list-base .mat-list-option .mat-list-item-content, mat-list.mat-list.list-with-chip.mat-list-base .mat-list-item .mat-list-item-content, mat-list.mat-list.list-with-chip.mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0 3px 0 0;
}
mat-selection-list.mat-selection-list.list-with-avatar.mat-list-base .mat-list-item, mat-selection-list.mat-selection-list.list-with-avatar.mat-list-base .mat-list-option, mat-selection-list.mat-selection-list.list-with-chip.mat-list-base .mat-list-item, mat-selection-list.mat-selection-list.list-with-chip.mat-list-base .mat-list-option, mat-list.mat-list.list-with-avatar.mat-list-base .mat-list-item, mat-list.mat-list.list-with-avatar.mat-list-base .mat-list-option, mat-list.mat-list.list-with-chip.mat-list-base .mat-list-item, mat-list.mat-list.list-with-chip.mat-list-base .mat-list-option {
  height: 56px;
}
mat-selection-list.mat-selection-list.list .mat-list-item, mat-selection-list.mat-selection-list.list .mat-list-option, mat-selection-list.mat-selection-list.list-with-avatar .mat-list-item, mat-selection-list.mat-selection-list.list-with-avatar .mat-list-option, mat-selection-list.mat-selection-list.list-with-icon-prefix .mat-list-item, mat-selection-list.mat-selection-list.list-with-icon-prefix .mat-list-option, mat-selection-list.mat-selection-list.list-with-toggle .mat-list-item, mat-selection-list.mat-selection-list.list-with-toggle .mat-list-option, mat-selection-list.mat-selection-list.list-with-chip .mat-list-item, mat-selection-list.mat-selection-list.list-with-chip .mat-list-option, mat-list.mat-list.list .mat-list-item, mat-list.mat-list.list .mat-list-option, mat-list.mat-list.list-with-avatar .mat-list-item, mat-list.mat-list.list-with-avatar .mat-list-option, mat-list.mat-list.list-with-icon-prefix .mat-list-item, mat-list.mat-list.list-with-icon-prefix .mat-list-option, mat-list.mat-list.list-with-toggle .mat-list-item, mat-list.mat-list.list-with-toggle .mat-list-option, mat-list.mat-list.list-with-chip .mat-list-item, mat-list.mat-list.list-with-chip .mat-list-option {
  background-color: #ffffff;
  padding: 0 16px;
}
mat-selection-list.mat-selection-list.list .option-content-wrapper, mat-selection-list.mat-selection-list.list-with-avatar .option-content-wrapper, mat-selection-list.mat-selection-list.list-with-icon-prefix .option-content-wrapper, mat-selection-list.mat-selection-list.list-with-toggle .option-content-wrapper, mat-selection-list.mat-selection-list.list-with-chip .option-content-wrapper, mat-list.mat-list.list .option-content-wrapper, mat-list.mat-list.list-with-avatar .option-content-wrapper, mat-list.mat-list.list-with-icon-prefix .option-content-wrapper, mat-list.mat-list.list-with-toggle .option-content-wrapper, mat-list.mat-list.list-with-chip .option-content-wrapper {
  display: flex;
  width: 100%;
}
mat-selection-list.mat-selection-list.list .option-content-infix, mat-selection-list.mat-selection-list.list-with-avatar .option-content-infix, mat-selection-list.mat-selection-list.list-with-icon-prefix .option-content-infix, mat-selection-list.mat-selection-list.list-with-toggle .option-content-infix, mat-selection-list.mat-selection-list.list-with-chip .option-content-infix, mat-list.mat-list.list .option-content-infix, mat-list.mat-list.list-with-avatar .option-content-infix, mat-list.mat-list.list-with-icon-prefix .option-content-infix, mat-list.mat-list.list-with-toggle .option-content-infix, mat-list.mat-list.list-with-chip .option-content-infix {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}
mat-selection-list.mat-selection-list.list .option-content-infix div, mat-selection-list.mat-selection-list.list-with-avatar .option-content-infix div, mat-selection-list.mat-selection-list.list-with-icon-prefix .option-content-infix div, mat-selection-list.mat-selection-list.list-with-toggle .option-content-infix div, mat-selection-list.mat-selection-list.list-with-chip .option-content-infix div, mat-list.mat-list.list .option-content-infix div, mat-list.mat-list.list-with-avatar .option-content-infix div, mat-list.mat-list.list-with-icon-prefix .option-content-infix div, mat-list.mat-list.list-with-toggle .option-content-infix div, mat-list.mat-list.list-with-chip .option-content-infix div {
  display: inline-flex;
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Medium";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
mat-selection-list.mat-selection-list.list .option-content-postfix, mat-selection-list.mat-selection-list.list-with-avatar .option-content-postfix, mat-selection-list.mat-selection-list.list-with-icon-prefix .option-content-postfix, mat-selection-list.mat-selection-list.list-with-toggle .option-content-postfix, mat-selection-list.mat-selection-list.list-with-chip .option-content-postfix, mat-list.mat-list.list .option-content-postfix, mat-list.mat-list.list-with-avatar .option-content-postfix, mat-list.mat-list.list-with-icon-prefix .option-content-postfix, mat-list.mat-list.list-with-toggle .option-content-postfix, mat-list.mat-list.list-with-chip .option-content-postfix {
  display: flex;
  margin: auto;
}
mat-selection-list.mat-selection-list.list .option-content-postfix mat-icon, mat-selection-list.mat-selection-list.list-with-avatar .option-content-postfix mat-icon, mat-selection-list.mat-selection-list.list-with-icon-prefix .option-content-postfix mat-icon, mat-selection-list.mat-selection-list.list-with-toggle .option-content-postfix mat-icon, mat-selection-list.mat-selection-list.list-with-chip .option-content-postfix mat-icon, mat-list.mat-list.list .option-content-postfix mat-icon, mat-list.mat-list.list-with-avatar .option-content-postfix mat-icon, mat-list.mat-list.list-with-icon-prefix .option-content-postfix mat-icon, mat-list.mat-list.list-with-toggle .option-content-postfix mat-icon, mat-list.mat-list.list-with-chip .option-content-postfix mat-icon {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
mat-selection-list.mat-selection-list.list .option-content-prefix, mat-selection-list.mat-selection-list.list-with-avatar .option-content-prefix, mat-selection-list.mat-selection-list.list-with-icon-prefix .option-content-prefix, mat-selection-list.mat-selection-list.list-with-toggle .option-content-prefix, mat-selection-list.mat-selection-list.list-with-chip .option-content-prefix, mat-list.mat-list.list .option-content-prefix, mat-list.mat-list.list-with-avatar .option-content-prefix, mat-list.mat-list.list-with-icon-prefix .option-content-prefix, mat-list.mat-list.list-with-toggle .option-content-prefix, mat-list.mat-list.list-with-chip .option-content-prefix {
  padding-right: 11px;
}
mat-selection-list.mat-selection-list.list mat-icon, mat-selection-list.mat-selection-list.list-with-avatar mat-icon, mat-selection-list.mat-selection-list.list-with-icon-prefix mat-icon, mat-selection-list.mat-selection-list.list-with-toggle mat-icon, mat-selection-list.mat-selection-list.list-with-chip mat-icon, mat-list.mat-list.list mat-icon, mat-list.mat-list.list-with-avatar mat-icon, mat-list.mat-list.list-with-icon-prefix mat-icon, mat-list.mat-list.list-with-toggle mat-icon, mat-list.mat-list.list-with-chip mat-icon {
  margin-top: 5px;
}
mat-selection-list.mat-selection-list.list mat-chip.mat-chip mat-icon, mat-selection-list.mat-selection-list.list-with-avatar mat-chip.mat-chip mat-icon, mat-selection-list.mat-selection-list.list-with-icon-prefix mat-chip.mat-chip mat-icon, mat-selection-list.mat-selection-list.list-with-toggle mat-chip.mat-chip mat-icon, mat-selection-list.mat-selection-list.list-with-chip mat-chip.mat-chip mat-icon, mat-list.mat-list.list mat-chip.mat-chip mat-icon, mat-list.mat-list.list-with-avatar mat-chip.mat-chip mat-icon, mat-list.mat-list.list-with-icon-prefix mat-chip.mat-chip mat-icon, mat-list.mat-list.list-with-toggle mat-chip.mat-chip mat-icon, mat-list.mat-list.list-with-chip mat-chip.mat-chip mat-icon {
  margin-top: 0;
}
mat-selection-list.mat-selection-list.list mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked, mat-selection-list.mat-selection-list.list-with-avatar mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked, mat-selection-list.mat-selection-list.list-with-icon-prefix mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked, mat-selection-list.mat-selection-list.list-with-toggle mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked, mat-selection-list.mat-selection-list.list-with-chip mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked, mat-list.mat-list.list mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked, mat-list.mat-list.list-with-avatar mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked, mat-list.mat-list.list-with-icon-prefix mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked, mat-list.mat-list.list-with-toggle mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked, mat-list.mat-list.list-with-chip mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: #b39841;
}
mat-selection-list.mat-selection-list.list-with-toggle .option-content-postfix mat-slide-toggle.mat-slide-toggle.mat-checked.mat-accent .mat-slide-toggle-bar, mat-list.mat-list.list-with-toggle .option-content-postfix mat-slide-toggle.mat-slide-toggle.mat-checked.mat-accent .mat-slide-toggle-bar {
  background-color: #b39841;
}
mat-selection-list.mat-selection-list.list-with-toggle .option-content-postfix mat-slide-toggle.mat-slide-toggle .mat-slide-toggle-thumb, mat-list.mat-list.list-with-toggle .option-content-postfix mat-slide-toggle.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: #ffffff;
}
mat-selection-list.mat-selection-list.list-with-toggle .option-content-postfix mat-slide-toggle.mat-slide-toggle .mat-slide-toggle-ripple, mat-list.mat-list.list-with-toggle .option-content-postfix mat-slide-toggle.mat-slide-toggle .mat-slide-toggle-ripple {
  display: none;
}
mat-selection-list.mat-selection-list.list-with-toggle mat-pseudo-checkbox.mat-pseudo-checkbox, mat-list.mat-list.list-with-toggle mat-pseudo-checkbox.mat-pseudo-checkbox {
  display: none;
}

.mat-list-base.list-with-avatar mat-list-option.mat-list-option:hover .mat-list-item-content::after, .mat-list-base.list-with-icon-prefix mat-list-option.mat-list-option:hover .mat-list-item-content::after {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-list-base.list-with-avatar mat-list-option.mat-list-option:focus .mat-list-item-content::after, .mat-list-base.list-with-icon-prefix mat-list-option.mat-list-option:focus .mat-list-item-content::after {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-list-base.list-with-avatar .mat-list-item .mat-list-item-content-reverse, .mat-list-base.list-with-avatar .mat-list-option .mat-list-item-content-reverse, .mat-list-base.list-with-icon-prefix .mat-list-item .mat-list-item-content-reverse, .mat-list-base.list-with-icon-prefix .mat-list-option .mat-list-item-content-reverse {
  padding: 0 3px 0 0;
}
.mat-list-base.list-with-avatar .mat-list-item.mat-list-item-with-avatar.mat-list-option .mat-list-item-content-reverse .mat-list-text, .mat-list-base.list-with-avatar .mat-list-item.mat-list-item-with-avatar.mat-list-option .mat-list-item-content .mat-list-text, .mat-list-base.list-with-avatar .mat-list-option.mat-list-item-with-avatar.mat-list-option .mat-list-item-content-reverse .mat-list-text, .mat-list-base.list-with-avatar .mat-list-option.mat-list-item-with-avatar.mat-list-option .mat-list-item-content .mat-list-text, .mat-list-base.list-with-icon-prefix .mat-list-item.mat-list-item-with-avatar.mat-list-option .mat-list-item-content-reverse .mat-list-text, .mat-list-base.list-with-icon-prefix .mat-list-item.mat-list-item-with-avatar.mat-list-option .mat-list-item-content .mat-list-text, .mat-list-base.list-with-icon-prefix .mat-list-option.mat-list-item-with-avatar.mat-list-option .mat-list-item-content-reverse .mat-list-text, .mat-list-base.list-with-icon-prefix .mat-list-option.mat-list-item-with-avatar.mat-list-option .mat-list-item-content .mat-list-text {
  padding: 0;
}
.mat-list-base.list-with-avatar .mat-list-item .mat-list-item-ripple, .mat-list-base.list-with-avatar .mat-list-option .mat-list-item-ripple, .mat-list-base.list-with-icon-prefix .mat-list-item .mat-list-item-ripple, .mat-list-base.list-with-icon-prefix .mat-list-option .mat-list-item-ripple {
  left: unset;
}
.mat-list-base.list-with-avatar .mat-list-item .mat-list-item-content::after, .mat-list-base.list-with-avatar .mat-list-option .mat-list-item-content::after, .mat-list-base.list-with-icon-prefix .mat-list-item .mat-list-item-content::after, .mat-list-base.list-with-icon-prefix .mat-list-option .mat-list-item-content::after {
  content: " ";
  height: 100%;
  position: absolute;
  right: 0;
}
.mat-list-base.list-with-avatar .mat-list-item:not(:last-child) .mat-list-item-content::after, .mat-list-base.list-with-avatar .mat-list-option:not(:last-child) .mat-list-item-content::after, .mat-list-base.list-with-icon-prefix .mat-list-item:not(:last-child) .mat-list-item-content::after, .mat-list-base.list-with-icon-prefix .mat-list-option:not(:last-child) .mat-list-item-content::after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-list-base.list-with-avatar .mat-list-item .mat-list-item-ripple, .mat-list-base.list-with-avatar .mat-list-option .mat-list-item-ripple {
  width: calc(100% - 51px);
}
.mat-list-base.list-with-avatar .mat-list-item-content::after {
  width: calc(100% - 51px);
}
.mat-list-base.list-with-avatar .mat-list-item .mat-list-avatar, .mat-list-base.list-with-avatar .mat-list-option .mat-list-avatar {
  background-color: #f4f2ea;
}
.mat-list-base.list-with-icon-prefix mat-icon {
  color: #b39841;
}
.mat-list-base.list-with-icon-prefix .mat-list-item .mat-list-item-ripple, .mat-list-base.list-with-icon-prefix .mat-list-option .mat-list-item-ripple {
  width: calc(100% - 35px);
}
.mat-list-base.list-with-icon-prefix .mat-list-item-content::after {
  width: calc(100% - 35px);
}

.mat-select-panel-wrap .mat-option-multiple {
  flex-direction: row-reverse;
  justify-content: space-around;
}

.cdk-overlay-pane .mat-select-panel[aria-multiselectable=true] mat-option.mat-selected {
  background-color: rgba(0, 0, 0, 0.04);
}
.cdk-overlay-pane .mat-select-panel[aria-multiselectable=true] mat-option mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.6);
}
.cdk-overlay-pane .mat-select-panel[aria-multiselectable=true] mat-option mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background: #b39841;
}
.cdk-overlay-pane .mat-select-panel[aria-multiselectable=true] mat-option mat-icon {
  height: 19px;
  width: 20px;
  font-size: 22px;
  color: #b39841;
}

mat-radio-button.mat-radio-button.radio-button-mat {
  margin: 8px;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-radio-disabled {
  opacity: 0.38;
}
mat-radio-button.mat-radio-button.radio-button-mat .mat-radio-container {
  margin-right: 8px;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-radio-checked.mat-accent .mat-radio-outer-circle {
  border-color: #b39841;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-radio-checked.mat-accent.cdk-focused .mat-radio-ripple {
  border: 1px solid #b39841;
  border-radius: 50%;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-radio-checked.mat-accent.cdk-focused .mat-ripple-element {
  background-color: #b39841;
  opacity: 0.12;
  transition: background-color 0s;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-radio-checked.mat-accent .mat-ripple-element {
  background-color: #b39841;
  transition: background-color 0s;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-radio-checked.mat-accent :active .mat-ripple-element {
  background-color: #b39841;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-radio-checked.mat-accent :hover .mat-ripple-element {
  background-color: #b39841;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-accent.cdk-focused .mat-radio-ripple {
  border: 1px solid #ffffff;
  border-radius: 50%;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-accent.cdk-focused .mat-ripple-element {
  background-color: #000000;
  opacity: 0.12;
  transition: background-color 0s;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-accent .mat-radio-inner-circle {
  background-color: #b39841;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-accent .mat-ripple-element {
  background-color: #000000;
  transition: background-color 0s;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-accent :active .mat-ripple-element {
  background-color: #000000;
}
mat-radio-button.mat-radio-button.radio-button-mat.mat-accent :hover .mat-ripple-element {
  background-color: #000000;
}

.search-icon-wrapper {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
}

mat-form-field.mat-form-field.serach, mat-form-field.mat-form-field.search {
  width: 100%;
}
mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-flex, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #f4f2ea;
}
mat-form-field.mat-form-field.serach.mat-form-field-invalid .mat-form-field-ripple, mat-form-field.mat-form-field.search.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #b00020 !important;
  height: 1px;
}
mat-form-field.mat-form-field.serach.mat-form-field-invalid .mat-form-field-flex > .mat-form-field-outline div, mat-form-field.mat-form-field.search.mat-form-field-invalid .mat-form-field-flex > .mat-form-field-outline div {
  border-color: #b00020;
  border-width: 1px;
}
mat-form-field.mat-form-field.serach mat-error.mat-error, mat-form-field.mat-form-field.search mat-error.mat-error {
  height: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: #b00020;
}
mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper {
  margin-top: 0;
}
mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex {
  height: 48px;
  padding-top: 0;
  padding-right: 0;
  border-radius: 0;
}
mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  height: 48px;
  top: 0;
}
mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline div, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline div, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline div, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline div {
  border-radius: 0;
}
mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  border: 0;
  padding: 0;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix input, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix input, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix input, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix input {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Montserrat-Medium";
  font-size: 15px;
  letter-spacing: 0.47px;
  line-height: 24px;
}
mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix, mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  top: -0.05em;
  display: flex;
  margin: auto;
}
mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix button, mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix button, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix button, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix button, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix button, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix button, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix button, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix button {
  height: 40px;
  width: 40px;
  margin: auto;
}
mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-divider, mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-divider, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-divider, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-divider, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-divider, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-divider, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-divider, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-divider {
  height: 32px;
  margin: 8px 3.5px;
}
mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix mat-icon, mat-form-field.mat-form-field.serach.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix mat-icon, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix mat-icon, mat-form-field.mat-form-field.serach.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix mat-icon, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix mat-icon, mat-form-field.mat-form-field.search.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix mat-icon, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix mat-icon, mat-form-field.mat-form-field.search.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix mat-icon {
  color: rgba(0, 0, 0, 0.6);
}
mat-form-field.mat-form-field.serach :hover .mat-form-field-flex > .mat-form-field-outline div, mat-form-field.mat-form-field.search :hover .mat-form-field-flex > .mat-form-field-outline div {
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 1px;
}
mat-form-field.mat-form-field.serach.mat-focused input, mat-form-field.mat-form-field.search.mat-focused input {
  caret-color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.serach.mat-focused .mat-form-field-flex > .mat-form-field-outline div, mat-form-field.mat-form-field.search.mat-focused .mat-form-field-flex > .mat-form-field-outline div {
  border-color: rgba(0, 0, 0, 0.6) !important;
  border-width: 1px;
}
mat-form-field.mat-form-field.serach.mat-focused .mat-form-field-underline::before, mat-form-field.mat-form-field.search.mat-focused .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.6) !important;
  height: 1px;
}
mat-form-field.mat-form-field.serach .mat-form-field-underline::before, mat-form-field.mat-form-field.search .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.12);
}
mat-form-field.mat-form-field.serach .mat-form-field-ripple, mat-form-field.mat-form-field.search .mat-form-field-ripple {
  height: unset;
}

/*----------------------------------------------------------------------------------------------Colors*/
::ng-deep mat-form-field.mat-form-field.select-field, ::ng-deep mat-form-field.mat-form-field.select-outline, ::ng-deep mat-form-field.mat-form-field.select-field--disabled, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled {
  width: 100%;
}
::ng-deep mat-form-field.mat-form-field.select-field .mat-form-field-label-wrapper, ::ng-deep mat-form-field.mat-form-field.select-outline .mat-form-field-label-wrapper, ::ng-deep mat-form-field.mat-form-field.select-field--disabled .mat-form-field-label-wrapper, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled .mat-form-field-label-wrapper {
  top: -0.74375em;
}
::ng-deep mat-form-field.mat-form-field.select-field--disabled mat-icon, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
::ng-deep mat-form-field.mat-form-field.select-field mat-hint, ::ng-deep mat-form-field.mat-form-field.select-outline mat-hint, ::ng-deep mat-form-field.mat-form-field.select-field--disabled mat-hint, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled mat-hint {
  height: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
}
::ng-deep mat-form-field.mat-form-field.select-field mat-error, ::ng-deep mat-form-field.mat-form-field.select-outline mat-error {
  height: 16px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
}
::ng-deep mat-form-field.mat-form-field.select-field label, ::ng-deep mat-form-field.mat-form-field.select-outline label, ::ng-deep mat-form-field.mat-form-field.select-field--disabled label, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled label {
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.47px;
  line-height: 24px;
}
::ng-deep mat-form-field.mat-form-field.select-field input, ::ng-deep mat-form-field.mat-form-field.select-field mat-select, ::ng-deep mat-form-field.mat-form-field.select-field textarea, ::ng-deep mat-form-field.mat-form-field.select-outline input, ::ng-deep mat-form-field.mat-form-field.select-outline mat-select, ::ng-deep mat-form-field.mat-form-field.select-outline textarea, ::ng-deep mat-form-field.mat-form-field.select-field--disabled input, ::ng-deep mat-form-field.mat-form-field.select-field--disabled mat-select, ::ng-deep mat-form-field.mat-form-field.select-field--disabled textarea, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled input, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled mat-select, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled textarea {
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.47px;
  line-height: 24px;
}
::ng-deep mat-form-field.mat-form-field.select-field--disabled label, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled label {
  color: rgba(0, 0, 0, 0.38);
}
::ng-deep mat-form-field.mat-form-field.select-field--disabled input, ::ng-deep mat-form-field.mat-form-field.select-field--disabled mat-select, ::ng-deep mat-form-field.mat-form-field.select-field--disabled textarea, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled input, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled mat-select, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled textarea {
  color: rgba(0, 0, 0, 0.38);
}
::ng-deep mat-form-field.mat-form-field.select-field .mat-placeholder-required, ::ng-deep mat-form-field.mat-form-field.select-outline .mat-placeholder-required {
  display: none;
}
::ng-deep mat-form-field.mat-form-field.select-field .mat-select-arrow, ::ng-deep mat-form-field.mat-form-field.select-outline .mat-select-arrow, ::ng-deep mat-form-field.mat-form-field.select-field--disabled .mat-select-arrow, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled .mat-select-arrow {
  border-color: rgba(0, 0, 0, 0.6);
  width: 8px;
  height: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-top: unset;
  border-right: unset;
  transform: rotate(315deg);
}
::ng-deep mat-form-field.mat-form-field.select-field mat-select[aria-expanded=true] .mat-select-arrow, ::ng-deep mat-form-field.mat-form-field.select-outline mat-select[aria-expanded=true] .mat-select-arrow {
  transform: rotate(135deg);
}
::ng-deep mat-form-field.mat-form-field.select-field .mat-form-field-subscript-wrapper, ::ng-deep mat-form-field.mat-form-field.select-outline .mat-form-field-subscript-wrapper {
  margin-top: 0;
  padding-top: 3px;
}
::ng-deep mat-form-field.mat-form-field.select-field .mat-form-field-infix, ::ng-deep mat-form-field.mat-form-field.select-outline .mat-form-field-infix, ::ng-deep mat-form-field.mat-form-field.select-field--disabled .mat-form-field-infix, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled .mat-form-field-infix {
  border-top: 0;
}
::ng-deep mat-form-field.mat-form-field.select-field .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker, ::ng-deep mat-form-field.mat-form-field.select-outline .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker, ::ng-deep mat-form-field.mat-form-field.select-field--disabled .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker {
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.47px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-form-field-hide-placeholder .mat-form-field-label-wrapper, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled.mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
  height: 56px;
  padding: 0;
  top: -0.64375em;
}
::ng-deep mat-form-field.mat-form-field.select-outline .mat-form-field-outline, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled .mat-form-field-outline {
  background-color: transparent !important;
}
::ng-deep mat-form-field.mat-form-field.select-outline .mat-select-arrow-wrapper, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled .mat-select-arrow-wrapper {
  transform: translateY(-10%);
  height: 24px;
  width: 20px;
  vertical-align: middle;
}
::ng-deep mat-form-field.mat-form-field.select-outline .mat-form-field-flex, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled .mat-form-field-flex {
  height: 56px;
  padding: 2px 16px;
}
::ng-deep mat-form-field.mat-form-field.select-outline .mat-form-field-flex > .mat-form-field-outline, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled .mat-form-field-flex > .mat-form-field-outline {
  top: 0;
}
::ng-deep mat-form-field.mat-form-field.select-outline .mat-form-field-flex > .mat-form-field-outline div, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled .mat-form-field-flex > .mat-form-field-outline div {
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-radius: 0;
}
::ng-deep mat-form-field.mat-form-field.select-outline .mat-form-field-outline-gap {
  border-top-style: none;
}
::ng-deep mat-form-field.mat-form-field.select-outline :hover .mat-form-field-flex > .mat-form-field-outline div {
  border-color: rgba(0, 0, 0, 0.6);
  border-width: 1px;
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-form-field-hide-placeholder .mat-form-field-outline-gap {
  border-top-style: solid;
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-focused input, ::ng-deep mat-form-field.mat-form-field.select-outline.mat-focused textarea {
  caret-color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-focused label {
  color: #b39841 !important;
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-focused .mat-form-field-label .mat-form-field-required-marker {
  color: #b39841;
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-focused .mat-form-field-flex > .mat-form-field-outline div {
  border-color: #b39841;
  border-width: 1px;
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-focused.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-style: none;
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-focused .mat-select-arrow {
  border-color: rgba(0, 0, 0, 0.6);
}
::ng-deep mat-form-field.mat-form-field.select-outline .mat-form-field-outline {
  background-color: #ffffff;
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-form-field-invalid input, ::ng-deep mat-form-field.mat-form-field.select-outline.mat-form-field-invalid textarea {
  caret-color: rgba(0, 0, 0, 0.87);
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-form-field-invalid mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-form-field-invalid mat-error {
  color: #b00020;
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-form-field-invalid label {
  color: rgba(0, 0, 0, 0.87);
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-form-field-invalid .mat-form-field-flex > .mat-form-field-outline div {
  border-color: #b00020;
}
::ng-deep mat-form-field.mat-form-field.select-outline.mat-form-field-invalid :hover .mat-form-field-flex > .mat-form-field-outline div {
  border-color: rgba(0, 0, 0, 0.6);
}
::ng-deep mat-form-field.mat-form-field.select-field .mat-form-field-flex, ::ng-deep mat-form-field.mat-form-field.select-field--disabled .mat-form-field-flex {
  background-color: #f4f2ea;
  border-radius: 0;
  padding: 16px 16px 0px;
}
::ng-deep mat-form-field.mat-form-field.select-field .mat-select-arrow-wrapper, ::ng-deep mat-form-field.mat-form-field.select-field--disabled .mat-select-arrow-wrapper {
  transform: translateY(-30%);
  height: 24px;
  width: 20px;
  vertical-align: middle;
}
::ng-deep mat-form-field.mat-form-field.select-field.mat-form-field-invalid input, ::ng-deep mat-form-field.mat-form-field.select-field.mat-form-field-invalid textarea {
  caret-color: rgba(0, 0, 0, 0.87);
}
::ng-deep mat-form-field.mat-form-field.select-field.mat-form-field-invalid mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
::ng-deep mat-form-field.mat-form-field.select-field.mat-form-field-invalid mat-error {
  color: #b00020;
}
::ng-deep mat-form-field.mat-form-field.select-field.mat-form-field-invalid label {
  color: rgba(0, 0, 0, 0.87);
}
::ng-deep mat-form-field.mat-form-field.select-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #b00020 !important;
  height: 1px;
}
::ng-deep mat-form-field.mat-form-field.select-field .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
::ng-deep mat-form-field.mat-form-field.select-field :hover .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.6) !important;
  height: 1px;
}
::ng-deep mat-form-field.mat-form-field.select-field :hover .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
::ng-deep mat-form-field.mat-form-field.select-field.mat-focused input, ::ng-deep mat-form-field.mat-form-field.select-field.mat-focused textarea {
  caret-color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
}
::ng-deep mat-form-field.mat-form-field.select-field.mat-focused label {
  color: #b39841;
}
::ng-deep mat-form-field.mat-form-field.select-field.mat-focused .mat-form-field-label .mat-form-field-required-marker {
  color: #b39841;
}
::ng-deep mat-form-field.mat-form-field.select-field.mat-focused .mat-form-field-ripple {
  background-color: #b39841 !important;
  height: 1px;
}
::ng-deep mat-form-field.mat-form-field.select-field.mat-focused .mat-select-arrow {
  border-color: rgba(0, 0, 0, 0.6);
}
::ng-deep .cdk-overlay-pane {
  transform: none !important;
}
::ng-deep .cdk-overlay-pane .mat-select-panel.select-panel-fill {
  top: 33px;
}
::ng-deep .cdk-overlay-pane .mat-select-panel.select-panel-outline {
  top: 39px;
}
::ng-deep .cdk-overlay-pane .mat-select-panel {
  position: absolute;
  left: -15px;
  transform: none !important;
  min-width: calc(100% + 32px) !important;
  border-radius: 0;
}
::ng-deep .cdk-overlay-pane .mat-select-panel mat-option {
  height: 40px;
  padding-right: 7px;
}
::ng-deep .cdk-overlay-pane .mat-select-panel mat-option .mat-option-text {
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
::ng-deep .cdk-overlay-pane .mat-select-panel mat-option :not(.mat-option-disabled) .mat-option-text {
  color: rgba(0, 0, 0, 0.87);
}
::ng-deep .cdk-overlay-pane .mat-select-panel mat-option.mat-option-disabled .mat-option-text {
  color: rgba(0, 0, 0, 0.38);
}
::ng-deep .mat-form-field.mat-focused.mat-warn .mat-select-arrow,
::ng-deep .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: rgba(0, 0, 0, 0.6);
}
::ng-deep mat-form-field.mat-form-field.select-outline .mat-form-field-flex, ::ng-deep mat-form-field.mat-form-field.select-outline--disabled .mat-form-field-flex {
  padding: 2px 12px;
}

.spinner-overlay mat-dialog-container.mat-dialog-container {
  box-shadow: unset;
  background-color: transparent;
}

.dark-backdrop {
  background-color: rgba(0, 0, 0, 0.75);
}

mat-tab-group.mat-tab-group.tab-fill .mat-tab-label, mat-tab-group.mat-tab-group.tab-nested .mat-tab-label {
  min-width: unset;
  min-height: 48px;
}
mat-tab-group.mat-tab-group.tab-icon .mat-tab-label, mat-tab-group.mat-tab-group.tab-icon-text .mat-tab-label {
  min-width: 120px;
}
mat-tab-group.mat-tab-group.tab-icon .mat-tab-label {
  min-height: 48px;
}
mat-tab-group.mat-tab-group.tab-icon-text .mat-tab-label {
  min-height: 72px;
}
mat-tab-group.mat-tab-group.tab-icon-text .mat-tab-label .mat-tab-label-content {
  min-height: 48px;
  flex-direction: column;
  justify-content: space-between;
}
mat-tab-group.mat-tab-group.tab-fill .mat-tab-label, mat-tab-group.mat-tab-group.tab-nested .mat-tab-label, mat-tab-group.mat-tab-group.tab-icon .mat-tab-label, mat-tab-group.mat-tab-group.tab-icon-text .mat-tab-label {
  opacity: 1;
}
mat-tab-group.mat-tab-group.tab-fill .mat-tab-label .mat-tab-label-content, mat-tab-group.mat-tab-group.tab-nested .mat-tab-label .mat-tab-label-content, mat-tab-group.mat-tab-group.tab-icon .mat-tab-label .mat-tab-label-content, mat-tab-group.mat-tab-group.tab-icon-text .mat-tab-label .mat-tab-label-content {
  height: 16px;
  color: rgba(127, 108, 46, 0.6);
  font-family: "Montserrat-Regular";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.07px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
}
mat-tab-group.mat-tab-group.tab-fill mat-tab-header, mat-tab-group.mat-tab-group.tab-nested mat-tab-header, mat-tab-group.mat-tab-group.tab-icon mat-tab-header, mat-tab-group.mat-tab-group.tab-icon-text mat-tab-header {
  border: 0;
}
mat-tab-group.mat-tab-group.tab-fill .mat-tab-label-active.mat-tab-label .mat-tab-label-content, mat-tab-group.mat-tab-group.tab-nested .mat-tab-label-active.mat-tab-label .mat-tab-label-content, mat-tab-group.mat-tab-group.tab-icon .mat-tab-label-active.mat-tab-label .mat-tab-label-content, mat-tab-group.mat-tab-group.tab-icon-text .mat-tab-label-active.mat-tab-label .mat-tab-label-content {
  color: #b39841;
}
mat-tab-group.mat-tab-group.tab-fill > mat-icon, mat-tab-group.mat-tab-group.tab-nested > mat-icon, mat-tab-group.mat-tab-group.tab-icon > mat-icon, mat-tab-group.mat-tab-group.tab-icon-text > mat-icon {
  color: #b39841;
}
mat-tab-group.mat-tab-group.tab-fill .mat-tab-label, mat-tab-group.mat-tab-group.tab-icon .mat-tab-label, mat-tab-group.mat-tab-group.tab-icon-text .mat-tab-label {
  background-color: #e6e0cd;
}
mat-tab-group.mat-tab-group.tab-fill .mat-tab-label-active.mat-tab-label, mat-tab-group.mat-tab-group.tab-icon .mat-tab-label-active.mat-tab-label, mat-tab-group.mat-tab-group.tab-icon-text .mat-tab-label-active.mat-tab-label {
  background-color: #ffffff;
}
mat-tab-group.mat-tab-group.tab-fill > .mat-ripple, mat-tab-group.mat-tab-group.tab-icon > .mat-ripple, mat-tab-group.mat-tab-group.tab-icon-text > .mat-ripple {
  background-color: #e6e0cd;
}
mat-tab-group.mat-tab-group.tab-fill > .mat-ripple .mat-tab-header-pagination-chevron, mat-tab-group.mat-tab-group.tab-icon > .mat-ripple .mat-tab-header-pagination-chevron, mat-tab-group.mat-tab-group.tab-icon-text > .mat-ripple .mat-tab-header-pagination-chevron {
  border-color: rgba(127, 108, 46, 0.6);
}

.tab {
  display: inline-block;
  height: auto;
  width: auto;
  padding: 20px 30px 15px 30px;
  color: #444444;
  background: repeating-linear-gradient(-40deg, #e6e0cd, #e6e0cd 5px, #d7ceb0 5px, #d7ceb0 7.5px);
  border: none;
  font-size: 15px;
  font-family: "Montserrat-Regular", sans-serif;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  margin-right: 5px;
}
.tab.active {
  color: #7F6C2E;
  border: 1px solid #c0bbaa;
  border-bottom: none;
  background: white;
}

mat-form-field.mat-form-field.text-area-field, mat-form-field.mat-form-field.text-area-outline, mat-form-field.mat-form-field.text-area-field--disabled, mat-form-field.mat-form-field.text-area-outline--disabled {
  width: 100%;
}
mat-form-field.mat-form-field.text-area-field .mat-form-field-label-wrapper, mat-form-field.mat-form-field.text-area-outline .mat-form-field-label-wrapper, mat-form-field.mat-form-field.text-area-field--disabled .mat-form-field-label-wrapper, mat-form-field.mat-form-field.text-area-outline--disabled .mat-form-field-label-wrapper {
  top: -0.74375em;
}
mat-form-field.mat-form-field.text-area-field--disabled mat-icon, mat-form-field.mat-form-field.text-area-outline--disabled mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
mat-form-field.mat-form-field.text-area-field mat-hint, mat-form-field.mat-form-field.text-area-outline mat-hint, mat-form-field.mat-form-field.text-area-field--disabled mat-hint, mat-form-field.mat-form-field.text-area-outline--disabled mat-hint {
  height: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
}
mat-form-field.mat-form-field.text-area-field mat-error, mat-form-field.mat-form-field.text-area-outline mat-error {
  height: 16px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
}
mat-form-field.mat-form-field.text-area-field label, mat-form-field.mat-form-field.text-area-outline label, mat-form-field.mat-form-field.text-area-field--disabled label, mat-form-field.mat-form-field.text-area-outline--disabled label {
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.47px;
  line-height: 24px;
}
mat-form-field.mat-form-field.text-area-field input, mat-form-field.mat-form-field.text-area-field mat-select, mat-form-field.mat-form-field.text-area-field textarea, mat-form-field.mat-form-field.text-area-outline input, mat-form-field.mat-form-field.text-area-outline mat-select, mat-form-field.mat-form-field.text-area-outline textarea, mat-form-field.mat-form-field.text-area-field--disabled input, mat-form-field.mat-form-field.text-area-field--disabled mat-select, mat-form-field.mat-form-field.text-area-field--disabled textarea, mat-form-field.mat-form-field.text-area-outline--disabled input, mat-form-field.mat-form-field.text-area-outline--disabled mat-select, mat-form-field.mat-form-field.text-area-outline--disabled textarea {
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.47px;
  line-height: 24px;
}
mat-form-field.mat-form-field.text-area-field--disabled label, mat-form-field.mat-form-field.text-area-outline--disabled label {
  color: rgba(0, 0, 0, 0.38);
}
mat-form-field.mat-form-field.text-area-field--disabled input, mat-form-field.mat-form-field.text-area-field--disabled mat-select, mat-form-field.mat-form-field.text-area-field--disabled textarea, mat-form-field.mat-form-field.text-area-outline--disabled input, mat-form-field.mat-form-field.text-area-outline--disabled mat-select, mat-form-field.mat-form-field.text-area-outline--disabled textarea {
  color: rgba(0, 0, 0, 0.38);
}
mat-form-field.mat-form-field.text-area-field .mat-placeholder-required, mat-form-field.mat-form-field.text-area-outline .mat-placeholder-required {
  display: none;
}
mat-form-field.mat-form-field.text-area-field .mat-select-arrow, mat-form-field.mat-form-field.text-area-outline .mat-select-arrow, mat-form-field.mat-form-field.text-area-field--disabled .mat-select-arrow, mat-form-field.mat-form-field.text-area-outline--disabled .mat-select-arrow {
  border-color: rgba(0, 0, 0, 0.6);
  width: 8px;
  height: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-top: unset;
  border-right: unset;
  transform: rotate(315deg);
}
mat-form-field.mat-form-field.text-area-field mat-select[aria-expanded=true] .mat-select-arrow, mat-form-field.mat-form-field.text-area-outline mat-select[aria-expanded=true] .mat-select-arrow {
  transform: rotate(135deg);
}
mat-form-field.mat-form-field.text-area-field .mat-form-field-subscript-wrapper, mat-form-field.mat-form-field.text-area-outline .mat-form-field-subscript-wrapper {
  margin-top: 0;
  padding-top: 3px;
}
mat-form-field.mat-form-field.text-area-field .mat-form-field-infix, mat-form-field.mat-form-field.text-area-outline .mat-form-field-infix, mat-form-field.mat-form-field.text-area-field--disabled .mat-form-field-infix, mat-form-field.mat-form-field.text-area-outline--disabled .mat-form-field-infix {
  border-top: 0;
}
mat-form-field.mat-form-field.text-area-field .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker, mat-form-field.mat-form-field.text-area-outline .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker, mat-form-field.mat-form-field.text-area-field--disabled .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker, mat-form-field.mat-form-field.text-area-outline--disabled .mat-form-field-infix .mat-form-field-label .mat-form-field-required-marker {
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.47px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.text-area-outline.mat-form-field-hide-placeholder .mat-form-field-label-wrapper, mat-form-field.mat-form-field.text-area-outline--disabled.mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
  height: 56px;
  padding: 0;
  top: -0.64375em;
}
mat-form-field.mat-form-field.text-area-outline .mat-form-field-outline, mat-form-field.mat-form-field.text-area-outline--disabled .mat-form-field-outline {
  background-color: transparent !important;
}
mat-form-field.mat-form-field.text-area-outline .mat-select-arrow-wrapper, mat-form-field.mat-form-field.text-area-outline--disabled .mat-select-arrow-wrapper {
  transform: translateY(-10%);
  height: 24px;
  width: 20px;
  vertical-align: middle;
}
mat-form-field.mat-form-field.text-area-outline .mat-form-field-flex, mat-form-field.mat-form-field.text-area-outline--disabled .mat-form-field-flex {
  height: 56px;
  padding: 2px 16px;
}
mat-form-field.mat-form-field.text-area-outline .mat-form-field-flex > .mat-form-field-outline, mat-form-field.mat-form-field.text-area-outline--disabled .mat-form-field-flex > .mat-form-field-outline {
  top: 0;
}
mat-form-field.mat-form-field.text-area-outline .mat-form-field-flex > .mat-form-field-outline div, mat-form-field.mat-form-field.text-area-outline--disabled .mat-form-field-flex > .mat-form-field-outline div {
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-radius: 0;
}
mat-form-field.mat-form-field.text-area-outline .mat-form-field-outline-gap {
  border-top-style: none;
}
mat-form-field.mat-form-field.text-area-outline :hover .mat-form-field-flex > .mat-form-field-outline div {
  border-color: rgba(0, 0, 0, 0.6);
  border-width: 1px;
}
mat-form-field.mat-form-field.text-area-outline.mat-form-field-hide-placeholder .mat-form-field-outline-gap {
  border-top-style: solid;
}
mat-form-field.mat-form-field.text-area-outline.mat-focused input, mat-form-field.mat-form-field.text-area-outline.mat-focused textarea {
  caret-color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.text-area-outline.mat-focused label {
  color: #b39841 !important;
}
mat-form-field.mat-form-field.text-area-outline.mat-focused .mat-form-field-label .mat-form-field-required-marker {
  color: #b39841;
}
mat-form-field.mat-form-field.text-area-outline.mat-focused .mat-form-field-flex > .mat-form-field-outline div {
  border-color: #b39841;
  border-width: 1px;
}
mat-form-field.mat-form-field.text-area-outline.mat-focused.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-style: none;
}
mat-form-field.mat-form-field.text-area-outline.mat-focused .mat-select-arrow {
  border-color: rgba(0, 0, 0, 0.6);
}
mat-form-field.mat-form-field.text-area-outline .mat-form-field-outline {
  background-color: #ffffff;
}
mat-form-field.mat-form-field.text-area-outline.mat-form-field-invalid input, mat-form-field.mat-form-field.text-area-outline.mat-form-field-invalid textarea {
  caret-color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.text-area-outline.mat-form-field-invalid mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
mat-form-field.mat-form-field.text-area-outline.mat-form-field-invalid mat-error {
  color: #b00020;
}
mat-form-field.mat-form-field.text-area-outline.mat-form-field-invalid label {
  color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.text-area-outline.mat-form-field-invalid .mat-form-field-flex > .mat-form-field-outline div {
  border-color: #b00020;
}
mat-form-field.mat-form-field.text-area-outline.mat-form-field-invalid :hover .mat-form-field-flex > .mat-form-field-outline div {
  border-color: rgba(0, 0, 0, 0.6);
}
mat-form-field.mat-form-field.text-area-field .mat-form-field-flex, mat-form-field.mat-form-field.text-area-field--disabled .mat-form-field-flex {
  background-color: #f4f2ea;
  border-radius: 0;
  padding: 16px 16px 0px;
}
mat-form-field.mat-form-field.text-area-field .mat-select-arrow-wrapper, mat-form-field.mat-form-field.text-area-field--disabled .mat-select-arrow-wrapper {
  transform: translateY(-30%);
  height: 24px;
  width: 20px;
  vertical-align: middle;
}
mat-form-field.mat-form-field.text-area-field.mat-form-field-invalid input, mat-form-field.mat-form-field.text-area-field.mat-form-field-invalid textarea {
  caret-color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.text-area-field.mat-form-field-invalid mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
mat-form-field.mat-form-field.text-area-field.mat-form-field-invalid mat-error {
  color: #b00020;
}
mat-form-field.mat-form-field.text-area-field.mat-form-field-invalid label {
  color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.text-area-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #b00020 !important;
  height: 1px;
}
mat-form-field.mat-form-field.text-area-field .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
mat-form-field.mat-form-field.text-area-field :hover .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.6) !important;
  height: 1px;
}
mat-form-field.mat-form-field.text-area-field :hover .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
mat-form-field.mat-form-field.text-area-field.mat-focused input, mat-form-field.mat-form-field.text-area-field.mat-focused textarea {
  caret-color: rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
}
mat-form-field.mat-form-field.text-area-field.mat-focused label {
  color: #b39841;
}
mat-form-field.mat-form-field.text-area-field.mat-focused .mat-form-field-label .mat-form-field-required-marker {
  color: #b39841;
}
mat-form-field.mat-form-field.text-area-field.mat-focused .mat-form-field-ripple {
  background-color: #b39841 !important;
  height: 1px;
}
mat-form-field.mat-form-field.text-area-field.mat-focused .mat-select-arrow {
  border-color: rgba(0, 0, 0, 0.6);
}

mat-form-field.mat-form-field.text-area-field textarea, mat-form-field.mat-form-field.text-area-outline textarea, mat-form-field.mat-form-field.text-area-field--disabled textarea, mat-form-field.mat-form-field.text-area-outline--disabled textarea {
  min-height: 24px;
}
mat-form-field.mat-form-field.text-area-field.mat-form-field-hide-placeholder .mat-form-field-label-wrapper, mat-form-field.mat-form-field.text-area-outline.mat-form-field-hide-placeholder .mat-form-field-label-wrapper, mat-form-field.mat-form-field.text-area-field--disabled.mat-form-field-hide-placeholder .mat-form-field-label-wrapper, mat-form-field.mat-form-field.text-area-outline--disabled.mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
  top: -0.54375em;
}
mat-form-field.mat-form-field.text-area-outline .mat-form-field-flex, mat-form-field.mat-form-field.text-area-outline--disabled .mat-form-field-flex {
  padding: 2px 12px;
}

mat-slide-toggle.mat-slide-toggle.switch-mat.mat-disabled {
  opacity: 0.38;
}
mat-slide-toggle.mat-slide-toggle.switch-mat .mat-slide-toggle-bar {
  margin-right: 15px;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-checked.mat-accent .mat-slide-toggle-bar {
  background-color: #b39841;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-checked.mat-accent.cdk-focused .mat-slide-toggle-ripple {
  border: 1px solid #b39841;
  border-radius: 50%;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-checked.mat-accent.cdk-focused .mat-ripple-element {
  background-color: #b39841;
  opacity: 0.12;
  transition: background-color 0s;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-checked.mat-accent .mat-ripple-element {
  background-color: #b39841;
  transition: background-color 0s;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-checked.mat-accent :active .mat-ripple-element {
  background-color: #b39841;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-checked.mat-accent :hover .mat-ripple-element {
  background-color: #b39841;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-accent.cdk-focused .mat-slide-toggle-ripple {
  border: 1px solid #ffffff;
  border-radius: 50%;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-accent.cdk-focused .mat-ripple-element {
  background-color: #000000;
  opacity: 0.12;
  transition: background-color 0s;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-accent .mat-slide-toggle-thumb {
  background-color: #ffffff;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-accent .mat-ripple-element {
  background-color: #000000;
  transition: background-color 0s;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-accent :active .mat-ripple-element {
  background-color: #000000;
}
mat-slide-toggle.mat-slide-toggle.switch-mat.mat-accent :hover .mat-ripple-element {
  background-color: #000000;
}

label.toggle {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 32px;
}
label.toggle.disabled {
  cursor: no-drop;
  pointer-events: none;
}
label.toggle.disabled span.slider {
  background-color: #f4f2ea;
}
label.toggle.disabled span.slider:before {
  background-color: #d4cfbe;
}
label.toggle input[type=checkbox].toggle {
  display: none;
}
label.toggle input[type=checkbox].toggle:checked + span.slider {
  background-color: #ffffff;
}
label.toggle input[type=checkbox].toggle:checked + span.slider:before {
  -webkit-transform: translateX(24px);
  -moz-transform: translateX(24px);
  transform: translateX(24px);
}
label.toggle input[type=checkbox].toggle:focus + span.slider {
  box-shadow: 0 0 1px #ffffff;
}
label.toggle span.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 32px;
  background-color: #ffffff;
  border: 1px solid #d4cfbe;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
label.toggle span.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  border-radius: 50%;
  background-color: #7F6C2E;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

a.link {
  height: 16px;
  color: #b39841;
  font-family: "Montserrat-Regular";
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.07px;
  line-height: 16px;
  text-decoration: none;
}
a.link:hover {
  color: #7F6C2E !important;
}

h1 {
  color: rgba(0, 0, 0, 0.87);
  font-family: "WarnockPro-Semibold", serif;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 48px;
}

h2 {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Semibold", sans-serif;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.39px;
  line-height: 40px;
}

h3 {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
}

h4 {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

h5 {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Semibold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

h6 {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Semibold", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

h2, h3, h4, h5, h6 {
  margin-top: 0;
}

.subtitle-primary {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Semibold";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
.subtitle-secondary {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Medium";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.body-primary, .body-secondary {
  font-family: "Montserrat-Regular";
}
.body-primary {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  letter-spacing: 0.47px;
  line-height: 24px;
}
.body-secondary {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 24px;
}

.caption-primary {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Regular";
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
}
.caption-secondary {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Semibold";
  font-size: 10px;
  letter-spacing: 0.42px;
  line-height: 16px;
}

* {
  box-sizing: border-box;
}

body#fullscreenoverlay * {
  overflow: unset;
}
body#fullscreenoverlay oir-custom-navigation div.main-container {
  z-index: 0 !important;
}

.number-avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f4f2ea;
  text-align: center;
  display: flex;
}
.number-avatar div {
  margin: auto;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Montserrat-Semibold";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.app-container {
  height: 100%;
  background-color: #f4f2ea;
}

.overline {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Medium";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.8px;
  line-height: 16px;
}

a {
  font-family: "Montserrat-Light", sans-serif;
  color: #333333;
  font-size: 15px;
  line-height: 20px;
  text-decoration: underline;
}
a:hover {
  color: #7F6C2E !important;
  cursor: pointer;
}

b {
  font-family: "Montserrat-Semibold", sans-serif;
  font-size: 15px;
  line-height: 20px;
}

label {
  font-family: "Montserrat-Semibold", sans-serif;
  font-size: 15px;
  color: #444444;
  font-weight: normal;
}
label.pref-label {
  font-family: "Montserrat-Light", sans-serif;
  font-weight: 300;
}

p, td {
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #444444;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.page-title {
  padding: 30px 0 35px 20px;
}

.expansion-panel-header {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Semibold", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
}

.expansion-panel-body-text {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 15px;
  letter-spacing: 0.47px;
  line-height: 24px;
}

.app-container > .body {
  height: 100%;
  padding: 0 30px;
  overflow-y: auto;
  overflow-x: hidden;
}
.app-container > .body .content {
  position: relative;
  padding: 25px 20px;
  background-color: #ffffff;
  margin-bottom: 25px;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=radio] {
  display: inline-block;
  height: 24px;
  width: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../assets/img/off-radio.svg");
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 6px;
  outline: none;
}
input[type=radio]:focus {
  outline: none;
}
input[type=radio]:checked {
  background-image: url("../../assets/img/on-radio.svg");
}
input[type=radio]:disabled, input[type=radio][disabled] {
  background-image: url("../../assets/img/off-radio-dis.svg");
}
input[type=radio]:disabled + label, input[type=radio][disabled] + label {
  color: #999999;
}
input[type=radio]:disabled:hover, input[type=radio][disabled]:hover {
  cursor: no-drop;
}
input[type=radio]:checked:disabled, input[type=radio]:checked[disabled] {
  background-image: url("../../assets/img/on-radio-dis.svg");
  cursor: no-drop;
}
input[type=radio]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type=checkbox] {
  display: inline-block;
  height: 24px;
  width: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../assets/img/off-check.svg");
  vertical-align: middle;
  margin-bottom: 6px;
  outline: none;
}
input[type=checkbox]:focus {
  outline: none;
}
input[type=checkbox]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
input[type=checkbox]:checked {
  background-image: url("../../assets/img/on-check.svg");
}
input[type=checkbox]:disabled, input[type=checkbox][disabled] {
  background-image: url("../../assets/img/off-check-dis.svg");
  cursor: no-drop;
}
input[type=checkbox]:disabled + label, input[type=checkbox][disabled] + label {
  color: #999999;
}
input[type=checkbox]:disabled:hover, input[type=checkbox][disabled]:hover {
  cursor: no-drop;
}
input[type=checkbox]:checked:disabled, input[type=checkbox]:checked[disabled] {
  background-image: url("../../assets/img/on-check-dis.svg");
  cursor: no-drop;
}

label.top-label {
  display: block;
}
label.top-label.check {
  margin-left: 5px;
}

span.invalid-error {
  display: block;
  font-family: "Montserrat-Light", sans-serif;
  font-size: 14px;
  color: #c30e2e;
  margin-top: 5px;
}

.text-wrapper {
  display: inline-block;
}
.text-wrapper > input[type=text] {
  -webkit-appearance: none;
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  padding-top: 5px;
  height: 40px;
  width: 100%;
  background-color: white;
  border: 1px solid #d4cfbe;
  margin-top: 5px;
  padding-left: 15px;
  color: #444444;
  outline: none;
}
.text-wrapper > input[type=text]:focus {
  outline: none;
}
.text-wrapper > input[type=text]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999999;
}
.text-wrapper > input[type=text].valid {
  background-color: #d7f4f0;
  background-image: url("../../assets/img/validation.svg");
  background-repeat: no-repeat;
  background-position: right 9px top 7px;
  border: 1px solid #00c1a3;
}
.text-wrapper > input[type=text].invalid, .text-wrapper > input[type=text].invalid:disabled {
  background-color: #ffe5e9;
  color: #c30e2e;
  border: 1px solid #c30e2e;
}
.text-wrapper > input[type=text]:disabled, .text-wrapper > input[type=text][disabled] {
  background-color: #bfbbaa;
  border: 1px solid #bfbbaa;
}
.text-wrapper > input[type=text]:disabled:hover, .text-wrapper > input[type=text][disabled]:hover {
  cursor: no-drop;
}
.text-wrapper > input[type=text]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.text-wrapper > input[type=text]::-webkit-search-cancel-button:hover {
  background-image: url("../../assets/img/hover-x.svg");
}
.text-wrapper > textarea {
  display: block;
  -webkit-appearance: none;
  overflow: auto;
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  min-height: 40px;
  min-width: 350px;
  background-color: white;
  line-height: 25px;
  border: 1px solid #d4cfbe;
  padding-left: 15px;
  margin-top: 5px;
  color: #444444;
  resize: none;
  outline: none;
}
.text-wrapper > textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999999;
}
.text-wrapper > textarea.valid {
  background-color: #d7f4f0;
  background-image: url("../../assets/img/validation.svg");
  background-repeat: no-repeat;
  background-position: right 9px top 7px;
  border: 1px solid #00c1a3;
}
.text-wrapper > textarea.invalid, .text-wrapper > textarea.invalid:disabled {
  background-color: #ffe5e9;
  color: #c30e2e;
  border: 1px solid #c30e2e;
}
.text-wrapper > textarea:disabled, .text-wrapper > textarea[disabled] {
  background-color: #bfbbaa;
  border: 1px solid #bfbbaa;
}
.text-wrapper > textarea:disabled:hover, .text-wrapper > textarea[disabled]:hover {
  cursor: no-drop;
}

input[type=search] {
  display: inline-block;
  font-family: "Montserrat-Light", sans-serif;
  padding-top: 5px;
  padding-left: 15px;
  font-size: 15px;
  height: 40px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease;
  border: 1px solid #d4cfbe;
  background-color: white;
  background-image: url("../../assets/img/unselected-search.svg");
  background-position: right 9px top 7px;
  background-repeat: no-repeat;
  outline: none;
}
input[type=search]:focus {
  outline: none;
}
input[type=search]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999999;
}
input[type=search]::-webkit-search-cancel-button {
  display: none;
}
input[type=search]::-webkit-search-cancel-button:hover {
  background-image: url("../../assets/img/hover-x.svg");
}

.date-wrapper {
  display: inline-block;
}
.date-wrapper > .date-picker {
  display: block;
  height: 40px;
  width: 100%;
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  padding-left: 15px;
  padding-top: 5px;
  margin-top: 5px;
  border: 1px solid #d4cfbe;
  background-color: white;
  background-image: url("../../assets/img/unselected-cal.svg");
  background-position: right 9px top 7px;
  background-repeat: no-repeat;
}
.date-wrapper > .date-picker:hover {
  background-image: url("../../assets/img/selected-cal.svg");
}
.date-wrapper > .date-picker.disabled, .date-wrapper > .date-picker[disabled] {
  background-color: #bfbbaa;
  border: 1px solid #bfbbaa;
}
.date-wrapper > .date-picker.disabled:hover, .date-wrapper > .date-picker[disabled]:hover {
  background-image: url("../../assets/img/unselected-cal.svg");
  cursor: no-drop;
}
.date-wrapper > .date-picker.ng-invalid {
  background-color: #ffe5e9;
  border: 1px solid #c30e2e;
  color: #c30e2e;
}
.date-wrapper > .date-picker.ng-invalid:hover {
  background-image: url("../../assets/img/unselected-cal.svg");
}

.time-wrapper {
  display: inline-block;
}
.time-wrapper > .time-picker {
  display: block;
  height: 40px;
  width: 100%;
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
  padding-left: 15px;
  padding-top: 5px;
  margin-top: 5px;
  border: 1px solid #d4cfbe;
  background-color: white;
  background-image: url("../../assets/img/unselected-clock.svg");
  background-position: right 9px top 7px;
  background-repeat: no-repeat;
}
.time-wrapper > .time-picker::-ms-clear {
  display: none;
}
.time-wrapper > .time-picker::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.time-wrapper > .time-picker:hover {
  background-image: url("../../assets/img/selected-clock.svg");
}
.time-wrapper > .time-picker:disabled, .time-wrapper > .time-picker[disabled] {
  background-color: #bfbbaa;
  border: 1px solid #bfbbaa;
}
.time-wrapper > .time-picker:disabled:hover, .time-wrapper > .time-picker[disabled]:hover {
  background-image: url("../../assets/img/unselected-clock.svg");
  cursor: no-drop;
}
.time-wrapper > .time-picker.ng-invalid {
  background-color: #ffe5e9;
  border: 1px solid #c30e2e;
  color: #c30e2e;
}
.time-wrapper > .time-picker.ng-invalid:hover {
  background-image: url("../../assets/img/unselected-clock.svg");
}

.error-wrapper {
  position: absolute;
  top: 80px;
  max-width: 310px;
}

.hr {
  display: block;
  height: 2px;
  width: 99.3%;
  background-color: #d4cfbe;
  margin-top: 30px;
  margin-bottom: 20px;
}

.pane-container {
  position: relative;
  width: 100%;
}
.pane-container .left-pane {
  display: inline-block;
  width: 49%;
  margin-right: 1%;
  vertical-align: top;
}
.pane-container .right-pane {
  display: inline-block;
  width: 49%;
  vertical-align: top;
}
.pane-container .right-pane .short-title {
  margin-bottom: 20px;
}
.pane-container .right-pane .short-title .short-p {
  background-color: #f4f2ea;
  border: none;
  margin-bottom: 10px;
}
.pane-container .right-pane .long-title {
  margin-bottom: 20px;
}
.pane-container .right-pane .long-title .long-p {
  background-color: #f4f2ea;
  border: none;
  margin-bottom: 10px;
}
.pane-container .full-width {
  width: 100%;
}
.pane-container .short-p {
  height: 30px;
  width: 100%;
  resize: none;
}
.pane-container .long-p {
  height: 325px;
  width: 100%;
  resize: none;
}
.pane-container .comments {
  display: block;
}
.pane-container .long-title {
  display: block;
}

.filter-wrapper {
  display: inline-block;
  min-height: 80px;
  height: auto;
  width: 100%;
  border: 1px solid #d4cfbe;
  border-left: none;
  border-right: none;
}
.filter-wrapper .filter-wrapper {
  border: none;
}
.filter-wrapper > h2 {
  display: inline-block;
}
.filter-wrapper > .buttons {
  position: absolute;
  margin-top: 15px;
  display: inline-block;
  right: 0;
}
.filter-wrapper > .buttons > p {
  display: inline-block;
  margin-right: 20px;
}
.filter-wrapper > .buttons > .sort {
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-left: 60px;
  background-repeat: no-repeat;
}
.filter-wrapper > .buttons > .sort.closed {
  background-image: url("../../assets/img/closed-chev-big.svg");
}
.filter-wrapper > .buttons > .sort.open {
  background-image: url("../../assets/img/open-chev-big.svg");
}

.right-chev {
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-right: 0.75%;
  background-image: url("../../assets/img/unfilled-chev-right.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.badge {
  display: inline-block;
  color: #444444;
  font-size: 15px;
  font-weight: 300;
  margin-left: 15px;
  text-align: center;
  vertical-align: top;
  margin-top: 9px;
  background-color: white;
  border: 1px solid #b39841;
  border-radius: 20px;
  padding: 7px 25px;
}

.committee-container {
  margin-bottom: 15px;
}
.committee-container label {
  display: block;
  margin-bottom: 10px;
}
.committee-container .dropdown {
  display: inline-block;
  margin-bottom: 15px;
  width: auto;
}
.committee-container .off-schedule {
  display: inline-block;
  vertical-align: top;
  position: relative;
  float: right;
}
.committee-container .off-schedule p {
  display: inline-block;
  margin-left: 10px;
}
.committee-container .off-selected {
  display: none;
}
.committee-container .off-selected .accordion {
  width: 100%;
}
.committee-container .off-selected .accordion .panel {
  margin: 0 25px;
  width: auto;
}
.committee-container .off-selected .accordion .panel .header p {
  font-weight: bold;
}
.committee-container .off-selected .accordion .body {
  position: relative;
  background-color: #f4f2ea;
  overflow-y: hidden;
  padding: 30px 20px;
}
.committee-container .off-selected #off-schedule-table {
  width: 100%;
}
.committee-container .off-selected #off-schedule-table .text-wrapper {
  width: 100%;
}
.committee-container .off-selected #off-schedule-table .text-wrapper label {
  display: block;
}
.committee-container .off-selected #off-schedule-table .text-wrapper input[type=text] {
  width: 100%;
}
.committee-container .off-selected #off-schedule-table tr#divider {
  height: 20px;
}
.committee-container .off-selected #off-schedule-table td#divider {
  width: 60px;
}

.date-container {
  margin-bottom: 15px;
}
.date-container label {
  display: block;
  margin-bottom: 10px;
}
.date-container .date-wrapper label {
  display: block;
}
.date-container .date-wrapper .date-picker {
  display: block;
  height: 40px;
  min-width: 350px;
  font-size: 15px;
  padding-top: 5px;
  margin-top: 5px;
  border: 1px solid #d4cfbe;
  background-color: white;
  background-image: url("../../assets/img/unselected-cal.svg");
  background-position: right 9px top 7px;
  background-repeat: no-repeat;
}
.date-container .date-wrapper .date-picker :hover {
  background-image: url("../../assets/img/selected-cal.svg");
}
.date-container .date-wrapper .date-picker :disabled, .date-container .date-wrapper .date-picker[disabled] {
  background-color: #bfbbaa;
  border: 1px solid #bfbbaa;
}
.date-container .date-wrapper .date-picker :disabled :hover, .date-container .date-wrapper .date-picker[disabled] :hover {
  background-image: url("../../assets/img/unselected-cal.svg");
  cursor: no-drop;
}
.date-container .date-wrapper .date-picker .ng-invalid {
  background-color: #ffe5e9;
  border: 1px solid #c30e2e;
  color: #c30e2e;
}
.date-container .date-wrapper .date-picker .ng-invalid :hover {
  background-image: url("../../assets/img/selected-cal.svg");
}

.time-container .time-wrapper {
  display: inline-block;
}
.time-container .time-wrapper .time-picker {
  display: block;
  height: 32px;
  width: auto;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 3px;
  margin-top: 0 !important;
  border: 1px solid #d4cfbe;
  background-color: white;
  background-image: url("../../assets/img/unselected-clock.svg");
  background-position: right 9px top 7px;
  background-repeat: no-repeat;
}
.time-container .time-wrapper .time-picker :hover {
  background-image: url("../../assets/img/selected-clock.svg");
}
.time-container .time-wrapper .time-picker :disabled, .time-container .time-wrapper .time-picker[disabled] {
  background-color: #bfbbaa;
  border: 1px solid #bfbbaa;
}
.time-container .time-wrapper .time-picker :disabled :hover, .time-container .time-wrapper .time-picker[disabled] :hover {
  background-image: url("../../assets/img/unselected-clock.svg");
  cursor: no-drop;
}
.time-container .time-wrapper .time-picker .ng-invalid {
  background-color: #ffe5e9;
  border: 1px solid #c30e2e;
  color: #c30e2e;
}
.time-container .time-wrapper .time-picker .ng-invalid :hover {
  background-image: url("../../assets/img/selected-clock.svg");
}

.room-container {
  position: relative;
}
.room-container .room-instance {
  position: relative;
  padding: 20px;
  min-height: 40px;
  line-height: 40px;
  border: 2px solid #d4cfbe;
  border-left: 5px solid #7F6C2E;
  margin-bottom: 15px;
}
.room-container .room-instance .room-content {
  display: inline-block;
}
.room-container .room-instance .room-content label {
  display: inline-block;
}
.room-container .room-instance .room-content label #room-id {
  margin-right: 30px;
}
.room-container .room-instance .room-buttons {
  position: absolute;
  display: inline-block;
  right: 0;
  margin-right: 20px;
}
.room-container .accordion {
  width: 100%;
  margin-bottom: 15px;
}
.room-container .accordion .panel {
  margin: 0 25px;
  width: auto;
}
.room-container .accordion .panel .header {
  padding: 0;
}
.room-container .accordion .panel .header p {
  margin-left: 20px;
}
.room-container .accordion .body {
  position: relative;
  background-color: #f4f2ea;
  padding: 30px 20px;
  overflow-y: hidden;
}
.room-container .accordion .body .body-left {
  position: relative;
  display: inline-block;
  width: 29%;
  vertical-align: top;
}
.room-container .accordion .body .body-right {
  position: relative;
  display: inline-block;
  width: 70%;
  height: 150px;
  vertical-align: top;
}
.room-container .accordion .body .body-right .right-top {
  position: absolute;
  right: 0;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .room-container .accordion .body .body-right .right-top {
    margin-top: 0;
  }
  .room-container .accordion .body .body-right .right-top .divider {
    height: 4px;
  }
}
.room-container .accordion .body .body-right .buttons {
  position: relative;
  padding-top: 100px;
}
.room-container .accordion .body .body-right .buttons .btn-med-secondary {
  position: absolute;
  right: 0;
  display: block;
}
.room-container .accordion .body .divider {
  display: block;
  height: 10px;
}
.room-container .accordion.disabled, .room-container .accordion[disabled] {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  opacity: 0.4;
}
.room-container .accordion.disabled:hover, .room-container .accordion[disabled]:hover {
  cursor: no-drop;
}

.lrg-label {
  display: block;
  font-size: 18px;
  margin-bottom: 15px;
}

.rec-end {
  margin-top: 30px;
}
.rec-end .text-wrapper {
  width: auto;
}
.rec-end .text-wrapper .input[type=text] {
  display: inline-block;
  width: 180px;
}
.rec-end .date-wrapper .date-picker {
  display: inline-block;
  width: 180px;
}

.remove-comm {
  display: inline-block;
  height: 24px;
  width: 24px;
  padding: 8px;
  background-image: url("../../assets/img/unfilled-circle-x.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 10px;
  vertical-align: middle;
  margin-top: 8px;
  background-color: transparent;
  border: 0;
  float: right;
}
.remove-comm:hover {
  background-image: url("../../assets/img/filled-circle-x.svg");
}

.dropdown.large {
  width: auto !important;
}
.dropdown.large select {
  background-position: right 9px top 7px;
  padding-right: 40px;
}
.dropdown.large select::-ms-expand {
  display: none;
}

.divider-text {
  display: inline-block;
  padding: 0 30px;
  vertical-align: top;
  margin-top: 50px;
}

span#icon {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  height: 25px;
  width: 25px;
}
span#icon.televised {
  background-image: url("../../assets/img/to-be-televised.svg");
}
span#icon.private {
  background-image: url("../../assets/img/private.svg");
}
span#icon.presentation {
  background-image: url("../../assets/img/multimedia-presentation.svg");
}
span#icon.translation {
  background-image: url("../../assets/img/translation.svg");
}
span#icon.disabled {
  background-image: url("../../assets/img/disability.svg");
}
span#icon.video {
  background-image: url("../../assets/img/video-conferencing.svg");
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d4cfbe;
  -webkit-box-shadow: inset 0 0 1px #444444;
}

.customTabs:focus,
.customTabs:hover {
  outline: 1px solid !important;
  outline: auto -webkit-focus-ring-color !important;
  outline-color: #b39841 !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999999;
}

::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
  color: #999999;
}

.no-overflow {
  overflow: hidden;
}

ckeditor div.ck-font-size-dropdown div.ck-dropdown__panel {
  max-height: 120px;
  overflow-y: scroll;
}

figure.table table,
figure.table td {
  border: 1px solid grey;
}

/*----------------------------------------------------------------------------------------------Colors*/
.clock-expansion-panel-header.cdk-drag.cdk-drag-preview span.mat-content mat-panel-title:first-child {
  flex-grow: 0;
}
.clock-expansion-panel-header.cdk-drag.cdk-drag-preview span.mat-content mat-panel-title:nth-child(2) {
  justify-content: space-between;
  margin-right: 96px;
}
.clock-expansion-panel-header.cdk-drag.cdk-drag-preview mat-icon {
  display: none;
}

::ng-deep .pre-chamber-clock-tab .slot-container, ::ng-deep .in-chamber-clock-tab .slot-container {
  gap: 40px;
}
::ng-deep .pre-chamber-clock-tab .slot-time-conatiner, ::ng-deep .in-chamber-clock-tab .slot-time-conatiner {
  min-width: 85px;
  margin-right: 0px !important;
}
::ng-deep .pre-chamber-clock-tab .slot-time-conatiner .minutes-wrapper, ::ng-deep .in-chamber-clock-tab .slot-time-conatiner .minutes-wrapper {
  display: flex;
  width: 100%;
}
::ng-deep .pre-chamber-clock-tab .slot-time-conatiner .slot-time, ::ng-deep .in-chamber-clock-tab .slot-time-conatiner .slot-time {
  flex-basis: 50%;
  text-align: right;
}
::ng-deep .pre-chamber-clock-tab .speaker-list-container .input-section, ::ng-deep .in-chamber-clock-tab .speaker-list-container .input-section {
  margin-right: 16px;
}
::ng-deep .pre-chamber-clock-tab :not(.edit-mode).speaker-list-container .input-section, ::ng-deep .in-chamber-clock-tab :not(.edit-mode).speaker-list-container .input-section {
  justify-content: unset !important;
  gap: 40px;
}
::ng-deep .pre-chamber-clock-tab .speakers-input-block, ::ng-deep .in-chamber-clock-tab .speakers-input-block {
  margin-right: 0px !important;
}
::ng-deep .pre-chamber-clock-tab .lock-icon-wrapper, ::ng-deep .in-chamber-clock-tab .lock-icon-wrapper {
  min-width: 44px;
}
::ng-deep .pre-chamber-clock-tab .content-time-conatiner, ::ng-deep .in-chamber-clock-tab .content-time-conatiner {
  min-width: 85px;
}
::ng-deep .pre-chamber-clock-tab .edit-view, ::ng-deep .in-chamber-clock-tab .edit-view {
  margin-left: 0px !important;
}
::ng-deep .pre-chamber-clock-tab .edit-group-time, ::ng-deep .in-chamber-clock-tab .edit-group-time {
  margin-right: 0px !important;
}
::ng-deep .pre-chamber-clock-tab .speaker-title, ::ng-deep .in-chamber-clock-tab .speaker-title {
  width: unset;
  flex: 1;
}
::ng-deep .pre-chamber-clock-tab .general-speakers-template.expansion-panel-container mat-accordion.mat-accordion form.slot-form, ::ng-deep .in-chamber-clock-tab .general-speakers-template.expansion-panel-container mat-accordion.mat-accordion form.slot-form {
  justify-content: unset !important;
  gap: 40px;
}
::ng-deep .pre-chamber-clock-tab .edit-mode oir-singleselect, ::ng-deep .pre-chamber-clock-tab .edit-mode oir-speaker-typeahead, ::ng-deep .pre-chamber-clock-tab .edit-mode .edit-group-title-wrapper, ::ng-deep .in-chamber-clock-tab .edit-mode oir-singleselect, ::ng-deep .in-chamber-clock-tab .edit-mode oir-speaker-typeahead, ::ng-deep .in-chamber-clock-tab .edit-mode .edit-group-title-wrapper {
  flex: 1;
}
::ng-deep .pre-chamber-clock-tab .edit-mode .slot-container, ::ng-deep .in-chamber-clock-tab .edit-mode .slot-container {
  margin-right: 14px !important;
}
::ng-deep .pre-chamber-clock-tab .edit-mode .speaker-list-container.edit-active .mat-list-item .input-section .speaker-title, ::ng-deep .in-chamber-clock-tab .edit-mode .speaker-list-container.edit-active .mat-list-item .input-section .speaker-title {
  margin: 0px !important;
  padding-top: 10px;
}
::ng-deep .pre-chamber-clock-tab .edit-mode .speaker-list-container .number-avatar, ::ng-deep .in-chamber-clock-tab .edit-mode .speaker-list-container .number-avatar {
  margin-top: 0px !important;
}

/*----------------------------------------------------------------------------------------------Colors*/
.app-container:not(.fullSized) oir-business-items-entry .expansion-panel-body-text {
  overflow-x: auto;
  max-width: calc(100vw - 390px);
}
.app-container:not(.fullSized) oir-business-items-entry .expansion-panel-body-text p, .app-container:not(.fullSized) oir-business-items-entry .expansion-panel-body-text td {
  line-height: normal;
}
.app-container.fullSized oir-business-items-entry .expansion-panel-body-text {
  overflow-x: auto;
  max-width: calc(100vw - 230px);
}
.app-container.fullSized oir-business-items-entry .expansion-panel-body-text p, .app-container.fullSized oir-business-items-entry .expansion-panel-body-text td {
  line-height: normal;
}

.spinner-bg {
  position: absolute;
  top: 0;
  opacity: 0.75;
  background-color: #000;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.spinner-wrapper {
  position: absolute;
  top: 50%;
  margin-top: -106.5px;
  left: 50%;
  margin-left: -250px;
}
.spinner-wrapper img {
  position: absolute;
  text-align: center;
  vertical-align: middle;
}

#stage {
  -webkit-perspective: 1500px;
  -moz-perspective: 1500px;
  perspective: 1500px;
}

#top-img {
  z-index: 2;
  -webkit-animation-name: top-img;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 0.5s;
  animation-name: top-img;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

#bottom-img {
  z-index: 1;
}

@-webkit-keyframes top-img {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
  }
}
@keyframes top-img {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
.slot-position {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin-right: 24px;
  border-radius: 50%;
  background-color: #f4f2ea;
  font-family: Montserrat-Bold, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
.slot-position.dragable-slot {
  cursor: pointer;
}

.slot-label {
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat-Regular, sans-serif;
  font-size: 15px;
  letter-spacing: 0.47px;
  line-height: 24px;
  margin: 0;
  flex-basis: 50%;
  flex-shrink: 1;
  padding-right: 16px;
}

/*----------------------------------------------------------------------------------------------Colors*/
.sidebar-dialog {
  height: 100%;
  width: 368px;
}
.sidebar-dialog mat-dialog-container.mat-dialog-container {
  margin: 0;
  padding: 0;
  border-radius: 0;
  overflow: hidden;
}
.sidebar-dialog mat-dialog-container.mat-dialog-container .sidebar-header {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  padding: 0 16px;
}
.sidebar-dialog mat-dialog-container.mat-dialog-container .sidebar-header .header-title {
  color: #ffffff;
  font-family: Montserrat-Medium, sans-serif;
  font-weight: 500;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1.8px;
  line-height: 16px;
  text-transform: uppercase;
}
.sidebar-dialog mat-dialog-container.mat-dialog-container .sidebar-header mat-icon {
  color: #ffffff;
  cursor: pointer;
}

.sidebar-dialog-overlay-dark {
  background-color: rgba(0, 0, 0, 0.8);
}

html {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

@-moz-document url-prefix() {
  body *:not(strong) {
    font-weight: lighter !important;
  }
  body * strong {
    font-weight: bold !important;
  }
  body * strong * {
    font-weight: bold !important;
  }
}
.mat-tooltip.white-tooltip {
  background-color: #ffffff;
  padding: 16px;
  margin-bottom: 5px;
  max-width: 280px;
  width: 280px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-family: "Montserrat-Medium", sans-serif;
  letter-spacing: 0.47px;
  line-height: 24px;
}

.main-content {
  margin: 0;
  padding: 40px 30px 58px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.main-content h1 {
  font-family: "WarnockPro-Semibold", sans-serif;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 48px;
}
.main-content .title-section {
  margin-bottom: 32px;
}
.main-content .title-section > h1 {
  padding: 0;
  margin: 0;
}
.main-content .page-heading {
  padding: 0;
  margin: 0 0 40px 0;
}

.no-items {
  text-align: center;
  margin: 6rem 0;
}

.clickable {
  cursor: pointer;
}

.list-panel {
  background-color: #ffffff;
  padding: 1.5rem;
}